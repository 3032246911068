import {Component} from "react";
import {ProgressBar} from "antd-mobile";
import {PlayerDailyTaskEntity} from "../../models/PlayerDailyTaskEntity";

interface TaskItemProps {
    data: PlayerDailyTaskEntity;
}

export class TaskItem extends Component<TaskItemProps, any> {
    render() {
        const {data} = this.props
        var ap = Math.min(data.activePoint * (data.task.totalActivePoint / data.task.requireAmount),data.task.totalActivePoint)
        return <div style={{
            width: "7.28rem",
            height: "1.24rem",
            background: "#3B7082",
            marginBottom: "1px",
            boxSizing: "border-box",
            padding: "0 0.12rem",
            display: "flex",
            flexDirection: "column",
            textAlign: "left"
        }}>
            <div style={{color: "#FFCC00", fontSize: "0.28rem"}}>{data.task.name}</div>
            <div style={{
                color: "#AAAAAA",
                fontSize: "0.24rem"
            }}>目标:{`${data.activePoint }/${data.task.requireAmount}`}</div>
            <div style={{
                width: "7rem",
                height: "0.18rem",
                position: "relative",
                textAlign: "center",
                marginTop: "0.1rem"
            }}>
                <ProgressBar percent={ap/data.task.totalActivePoint * 100} style={{
                    width: "7rem",
                    '--track-width': '0.2rem',
                    "--track-color": "#333333",
                    '--fill-color': "#FF0000",
                    border: "0.01rem solid #63BABD",
                    boxSizing: "border-box"
                }} rounded={false}></ProgressBar>
                <div style={{
                    position: "absolute",
                    top: "0",
                    width: "100%",
                    color: "white",
                    lineHeight: "0.22rem",
                    fontSize: "0.14rem",
                    height: "100%"
                }}>{`${ap}/${data.task.totalActivePoint}`}
                </div>
            </div>
        </div>
    }
}
