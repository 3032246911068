import {Component} from "react";
import {Button, Ellipsis, Image} from "antd-mobile";
import SkillEntity from "../../models/SkillEntity";
import ItemEntity from "../../models/ItemEntity";
import {GetAssetIcon, GetAssetIconByIcon} from "../../utlis/AssetsUtils";
import {PlayerItemEntity} from "../../models/PlayerItemEntity";
import {GetItemType, ItemTopType} from "../../enums/ItemType";

interface BackpackItemProps {
    data: PlayerItemEntity;
    onLoad: (PlayerItemEntity) => void;
    onUnLoad: (PlayerItemEntity) => void;
    onDestroy: (PlayerItemEntity) => void;
    onUpgrade: (PlayerItemEntity) => void;
}

export default class BackpackItem extends Component<BackpackItemProps, any> {

    render() {
        let {data} = this.props;
        const topType = GetItemType(data.item.type)
        var color = data.rarity === 1 ? "#00EEFF" : (data.rarity === 2 ? "#C800FF" : "#F6C509")
        return <div style={{
            width: "7.26rem",
            height: "1.12rem",
            margin: "1px 0 0",
            display: "flex",
            background: "#3B7082",
            padding: "0.06rem",
            boxSizing: "border-box"
        }}>
            <div style={{
                width: "1rem",
                height: "1rem",
                padding: "0.05rem",
                boxSizing: "border-box",
                position: "relative",
                border: `solid 0.05rem ${color}`
            }}>
                <Image src={GetAssetIconByIcon(data.item.type, data.item.icon, data.item.wuxing)}
                       style={{width: "0.8rem", height: "0.8rem"}}/>
                {(topType === ItemTopType.DefenseWeapon || topType === ItemTopType.AttackWeapon) && <div style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "0.24rem"
                }}>+{data.level}</div>}
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                // width: "4.5rem",
                flex: 1,
                textAlign: "left",
                margin: "0 0.12rem"
            }}>
                <div style={{fontSize: "0.24rem", color: color}}>{data.item.name + " x" + data.amount}</div>
                <Ellipsis style={{fontSize: "0.2rem"}} direction='end' rows={2} content={data.item.description}/>
            </div>
            <Button onClick={() => this.props.onUpgrade(data)} style={{
                width: "0.8rem",
                height: "0.64rem",
                padding: "0",
                fontSize: "0.24rem",
                lineHeight: "0.64rem",
                margin: "auto 0.12rem"
            }}>查看</Button>
            {
                GetItemType(data.item.type) !== ItemTopType.Material &&
                <Button onClick={() => data.state === 1 ? this.props.onUnLoad(data) : this.props.onLoad(data)}
                        style={{
                            width: "0.8rem",
                            height: "0.64rem",
                            padding: "0",
                            fontSize: "0.24rem",
                            lineHeight: "0.64rem",
                            margin: "auto 0.12rem"
                        }}>{data.state === 1 ? "卸下" : "装备"}</Button>
            }

            {
                (GetItemType(data.item.type) === ItemTopType.AttackWeapon || GetItemType(data.item.type) === ItemTopType.DefenseWeapon) &&
                <Button onClick={() => this.props.onDestroy(data)} style={{
                    width: "0.8rem",
                    height: "0.64rem",
                    padding: "0",
                    fontSize: "0.24rem",
                    lineHeight: "0.64rem",
                    margin: "auto 0.12rem"
                }}>分解</Button>
            }
        </div>;
    }
}
