import {Component} from "react";
import {inject, observer} from "mobx-react";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {IStore} from "../../../stores";
import {Avatar, Button, Modal, NavBar, ProgressBar} from "antd-mobile";
import {DungeonBattleEntity} from "../../../models/DungeonBattleEntity";
import {APIHelper} from "../../../utlis/APIHelper";
import {ModalUtils} from "../../../utlis/modalUtils";
import {WuXing} from "../../../enums/WuXing";
import {GetPlayerIcon} from "../../../utlis/AssetsUtils";
import {BattleStatus} from "../../../enums/battleStatus";
import BattleDungeon = APIHelper.BattleDungeon;
import GetDungeonInfo = APIHelper.GetDungeonInfo;
import BattleMenpai = APIHelper.BattleMenpai;

interface MenpaiBattleProps extends IRouterProps, IStore {

}

interface MenpaiBattleState {
    level: number;
    name: string;
    currentStage: number;
    menpai: any;
    details: any;
    logcat: Array<{
        selfHP: number;
        targetHP: number;
        log: string;
    }>
    renderLog: Array<{
        selfHP: number;
        targetHP: number;
        log: string;
    }>
    playerTotalHp: number;
    targetTotalHp: number;
    playerHp: number;
    targetHp: number;
    drops: string;
    battleWin: boolean
    battleStatus: BattleStatus;
    finished: boolean
    targetName: string;
    targetWuxing: number;
}

@inject("player", "config")
@observer
class MenpaiBattle extends Component<MenpaiBattleProps, MenpaiBattleState> {
    constructor(props: MenpaiBattleProps) {
        super(props);
        this.state = {
            level: 1,
            name: "",
            currentStage: 1,
            menpai: this.props.location.state,
            logcat: [],
            renderLog: [],
            playerTotalHp: this.props.player.equipmentProperty.health + props.player.currentPlayer.property.health,
            playerHp: this.props.player.equipmentProperty.health + props.player.currentPlayer.property.health,
            targetHp: 999,
            targetTotalHp: 999,
            targetName: "",
            drops: "",
            battleWin: false,
            battleStatus: BattleStatus.Prepare,
            finished: false,
            targetWuxing: 1,
            details: {
                total: 0,
                points: 0,
                times: 0,
                rank: 1
            }
        }
    }

    componentDidMount() {
        this.fetchMenpaiDetail();
    }

    fetchMenpaiDetail = () => {
        APIHelper.MenpaiDetails(this.state.menpai.id).then((res) => {
            this.setState({
                details: res.data
            })
        })
    }

    doBattle = async () => {
        if (this.state.details.times >= 20) {
            ModalUtils.ShowSingleConfirm("提示", "无可用匹配次数", () => {
            });
            return;
        }
        this.setState({
            logcat: [],
            renderLog: [],
            drops: "",
            battleWin: false,
            battleStatus: BattleStatus.DuringBattle
        })
        BattleMenpai(this.state.menpai.id).then((res) => {
            if (res.data.finish) {
                this.setState({
                    finished: true
                })
            }
            this.fetchMenpaiDetail();
            var drops = res.data.drops;
            var content = []
            this.setState({
                logcat: res.data.logcat,
                renderLog: [],
                battleWin: res.data.win,
                targetName: res.data.targetPlayer.nickname,
                targetWuxing: res.data.targetPlayer.wuxing,
                targetTotalHp: res.data.targetPlayer.totalHp,
                drops: content.join(",")
            }, this.loopLog)
        }).catch((err)=>{
            ModalUtils.ShowSingleConfirm("提示", err, () => {
            });
        })

    }

    loopLog = () => {
        if (this.state.renderLog.length >= this.state.logcat.length) {
            if (!this.state.battleWin) {
                ModalUtils.ShowSingleConfirm("很遗憾", "战斗失败", () => {
                });
            }
            this.setState({
                battleStatus: BattleStatus.Prepare
            })
            return
        }

        this.setState({
            playerHp: this.state.logcat[this.state.renderLog.length].selfHP,
            targetHp: this.state.logcat[this.state.renderLog.length].targetHP,
            renderLog: this.state.logcat.filter((v, i) => i <= this.state.renderLog.length),
        });
        setTimeout(this.loopLog, 500)
    }

    render() {
        var type = this.props.getQueryParams("type");
        var maps = type === "1" ? this.props.config.maps : this.props.config.dungeons;
        const {menpai} = this.state;
        const {player} = this.props;
        var renders = [];
        this.state.renderLog.forEach((l) => renders.push(l))
        return <div className="battle-menpai-container">
            <NavBar onBack={() => this.props.navigate(-1)}
                    style={{
                        height: "0.94rem",
                        background: "#09384D",
                        width: "100%",
                        boxSizing: "border-box"
                    }}>门派竞技{menpai.level === 1 ? "初级" : menpai.level === 2 ? "中级" : "高级"}</NavBar>

            <div style={{display: "flex", justifyContent: "flex-start", width: "100%", margin: "0.1rem"}}>
                <Avatar style={{width: "1.2rem", height: "1.2rem", marginLeft: "0.1rem"}}
                        src={GetPlayerIcon(this.props.player.currentPlayer.wuxing as WuXing)}></Avatar>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "4rem",
                    alignItems: "start",
                    padding: "0 0.1rem",
                    background: "#A91B1B",
                    justifyContent: "space-around",
                    boxSizing: "border-box",
                    marginLeft: "0.1rem",
                    border: "1px solid #F03F03"
                }}>
                    <div>{player.currentPlayer.nickname}</div>
                    <ProgressBar style={{width: "100%", '--fill-color': "#ff0000", "--track-color": "#616161"}}
                                 percent={this.state.playerHp / this.state.playerTotalHp * 100}></ProgressBar>
                    <div style={{
                        fontSize: "0.24rem",
                        marginBottom: "0.1rem"
                    }}>{this.state.playerHp}/{this.state.playerTotalHp}</div>
                </div>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
                margin: "0.1rem",
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    padding: "0 0.1rem",
                    width: "4rem",
                    justifyContent: "space-around",
                    background: "#2B5B75",
                    marginRight: "0.1rem",
                    boxSizing: "border-box",
                    border: "1px solid #072335"
                }}>
                    <div>{this.state.targetName || "待匹配···"}</div>
                    <ProgressBar style={{
                        width: "100%",
                        '--fill-color': "#ff0000",
                        "--track-color": "#616161",
                        transform: "scaleX(-1)"
                    }} percent={100 * this.state.targetHp / this.state.targetTotalHp}></ProgressBar>
                    <div style={{
                        fontSize: "0.24rem",
                        marginBottom: "0.1rem"
                    }}>{this.state.targetHp}/{this.state.targetTotalHp}</div>
                </div>
                <Avatar style={{width: "1.2rem", height: "1.2rem", marginRight: "0.1rem"}}
                        src={GetPlayerIcon(this.state.targetWuxing as WuXing)}></Avatar>
            </div>
            <div style={{display: "flex", justifyContent: "space-around", width: "100%"}}>
                <Button size={"small"} style={{}}
                        disabled={this.state.battleStatus === BattleStatus.DuringBattle}
                        onClick={this.doBattle}>快速战斗</Button>
                <Button size={"small"} style={{}}
                        disabled={this.state.battleStatus === BattleStatus.DuringBattle}
                        onClick={this.doBattle}>开始战斗</Button>
            </div>

            <div>———————————— 战斗日志 ————————————</div>
            <div className="menpai-logcat-container" style={{
                flex: 1,
                overflow: "scroll",
                width: "100%",
                padding: "0 0.2rem",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column"
            }}>
                {
                    this.state.battleStatus === BattleStatus.Finished && this.state.battleWin && <div
                        className="battle-logcat">获取奖励:{this.state.drops}</div>
                }
                {
                    renders.reverse().map((item, index) => <div
                        dangerouslySetInnerHTML={{__html: `${this.state.renderLog.length - index}:` + item.log}}
                        className="battle-logcat" key={index}></div>)
                }

            </div>
            <div className="bottom-info">
                <div style={{display: "flex", justifyContent: "space-between", fontSize: "0.28rem", color: "#FFCC00"}}>
                    <div>剩余战斗次数: {Math.min(Math.max(0, 20 - this.state.details.times), 20)}次</div>
                    <div>当前排名:{this.state.details.rank}</div>
                </div>
                <div style={{display: "flex", justifyContent: "start", fontSize: "0.28rem", lineHeight: "0.24rem"}}>
                    <span style={{color: "#FFCC00"}}>当前积分:</span>
                    <span>{this.state.details.points}</span>
                    <span style={{marginLeft: "0.32rem", color: "#FFCC00"}}>总积分:</span>
                    <span>{this.state.details.total}</span>
                </div>
            </div>
        </div>;
    }
}
export default withRouter(MenpaiBattle);
