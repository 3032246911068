import {Component, Fragment} from "react";
import {Avatar, Button, Image} from "antd-mobile";
import {GetItemTypeName, ItemType} from "../../enums/ItemType";
import {GetAssetIcon} from "../../utlis/AssetsUtils";
import {WuXing} from "../../enums/WuXing";
import ItemEntity from "../../models/ItemEntity";
import {PlayerItemEntity} from "../../models/PlayerItemEntity";

interface IEquipItemProps {
    icon?: string;
    item?: PlayerItemEntity;
    onEquipChange?: (boolean) => void;
    type?: ItemType;
    wuxing?: number;
    onClick: (PlayerItemEntity) => void
    onUnload: (PlayerItemEntity) => void

}

export default class EquipItem extends Component<IEquipItemProps, any> {

    render() {
        return <div
            style={{display: "flex", width: "1.35rem", margin: "0 0.1rem 0.12rem 0.1rem"}}>
            <div style={{
                width: "1.35rem",
                height: "1.35rem",
                background: "#072335",
                position: "relative",
                backgroundSize: "contain",
                borderRadius: "0.24rem",
                backgroundImage: "url(/images/ui/equip_bg.png)"
            }}>
                {
                    <Image className="equip-bg"
                           src={GetAssetIcon(this.props.type, 10, (this.props.wuxing || 0) as WuXing)}
                           style={{width: "0.85rem", height: "0.85rem", margin: "0.25rem"}}></Image>
                }
                {
                    !this.props.item && <div style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        color: "#3f87a0",
                        lineHeight: "1.35rem",
                        fontSize: "0.3rem"
                    }}>{GetItemTypeName(this.props.type)}</div>
                }

                {
                    this.props.item && <Fragment>
                        <Image
                            onClick={() => this.props.item && this.props.onClick(this.props.item)}
                            src={GetAssetIcon(this.props.item.item.type, this.props.item.item.requireLevel, (this.props.wuxing || 0) as WuXing)}
                            style={{width: "0.85rem", height: "0.85rem", margin: "0.25rem", position: "absolute"}}></Image>
                        <Button onClick={() => this.props.onUnload(this.props.item)} className="unload-equip">卸下</Button>
                        <div style={{
                            position: "absolute",
                            top: "0.2rem",
                            right: "0.2rem",
                            fontSize: "0.24rem"
                        }}>+{this.props.item.level}</div>
                    </Fragment>
                }

            </div>

        </div>;
    }
}
