import {Component} from "react";
import {Button, Ellipsis, Image} from "antd-mobile";
import './index.less'
import {TerritoryArea} from "../../models/TerritoryArea";
import {IStore} from "../../stores";
import {inject, observer} from "mobx-react";

interface TerritoryItemProps extends IStore{
    data: TerritoryArea;
    territoryPlayer: any;
    active: boolean;
    onBossClick: Function;
    onClaimClick: Function;

}

interface TerritoryItemState {
}
@inject("player", "config")
@observer
export default class TerritoryItem extends Component<TerritoryItemProps, TerritoryItemState> {

    constructor(props) {
        super(props);
    }

    render() {
        let {data, active,config} = this.props;
        const reward =  this.props.data.reward.map((item)=>{
           return `${item.amount} ${config.itemTemplateMap.get(item.itemId).name}/h`
        }).join(",");
        const player = this.props.data.player[0];
        return <div className={active || player ? "territory-item active" : "territory-item"}>
            <div className="territory-item-top">
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "4.5rem",
                    textAlign: "left",
                    margin: "0 0.12rem"
                }}>
                    <div className="title">{data.name}</div>
                    <Ellipsis className="description" direction='end' rows={2}
                              content={`适合Lv.${data.minRequireLevel}级-${data.maxRequireLevel}级`}/>
                </div>
                <div style={{display:"flex"}}>
                    {
                        active && <Button onClick={() => {this.props.onClaimClick()}} style={{
                            width: "1.2rem",
                            height: "0.64rem",
                            padding: "0",
                            fontSize: "0.28rem",
                            lineHeight: "0.64rem",
                            margin: "auto 0.12rem"
                        }}>领取</Button>
                    }

                    <Button disabled={active} onClick={() => this.props.onBossClick()} style={{
                        width: "1.2rem",
                        height: "0.64rem",
                        padding: "0",
                        fontSize: "0.28rem",
                        lineHeight: "0.64rem",
                        margin: "auto 0.12rem"
                    }}>{active ? "已占领" : "占领"}</Button>
                </div>

            </div>
            <div style={{fontSize:"0.2rem",textAlign:"left",padding:"0 0.4rem"}}>{reward}</div>
            {
                active && <div className="idle-info">
                    <span>占领时间{player.startTime.toString()}</span>
                    <span style={{color: "#FFCC00"}}></span>
                </div>
            }
            {
                !active && player && <div className="idle-info">
                    <span>占领时间{player.startTime.toString()}</span>
                    <span style={{color: "#FFCC00"}}>{player.player.nickname}<span style={{color:"white"}}>占领中</span></span>
                </div>
            }
        </div>;
    }
}
