import React, {Component} from "react";
import withRouter, {IRouterProps} from "../../utlis/withRouter";
import {Button, Form, Input, Toast} from "antd-mobile";
import {FormInstance} from "antd-mobile/es/components/form";
import {APIHelper} from "../../utlis/APIHelper";
import "./index.less"
import {Link} from "react-router-dom";
import FormItemTitle from "../../component/FormItemTitle";
import localforage from "localforage";
import {ModalUtils} from "../../utlis/modalUtils";

class Login extends Component<IRouterProps, any> {
    constructor(props) {
        super(props);
    }

    form: FormInstance;
    onLoginClick = () => {
        this.form.validateFields().then((value) => {
            ModalUtils.ShowLoading();

            APIHelper.Login(value).then((res) => {
                this.props.navigate({
                    pathname: "/server",
                }, {
                    replace: true
                })
            }).catch((msg) => {
                ModalUtils.ShowFailedMsg(msg);
            }).finally(()=>{
                ModalUtils.HideLoading();
            })
        }).catch((reason) => {
            // Toast.show(reason)
            console.log(11)

        })

    }
    onCreateAccountClick = () => {
        this.props.navigate({
            pathname: "/register"
        })
    }

    onChangePwdClick = () => {
        this.props.navigate({
            pathname: "/change"
        })
    }

    onRetrivePwdClick = () => {
        this.props.navigate({
            pathname: "/retrive"
        })
    }

    componentDidMount() {
        localforage.getItem("User_Token").then((token) => {
            if (token) {
                APIHelper.UserInfo().then(() => {
                    this.props.navigate({
                        pathname: "/server",
                    }, {
                        replace: true
                    })
                }).catch(() => {

                });
            }
        })

    }

    render() {
        return <div>
            <div style={{
                background: "#072335",
                height: "1.18rem",
                width: "100%",
                lineHeight: "1.18rem",
                fontSize: "0.34rem"
            }}>
                登录游戏
            </div>

            <Form className="login-form" ref={(ref) => this.form = ref}
                  layout="vertical" onFinish={this.onLoginClick}>
                <Form.Item name="username" label={<FormItemTitle title="账号" subTitle="输入账号"/>}>
                    <Input placeholder="请输入用户名"></Input>
                </Form.Item>
                <Form.Item name="password" label={<FormItemTitle title="账号" subTitle="输入密码"/>}>
                    <Input type="password" placeholder="请输入密码"></Input>
                </Form.Item>
                <Form.Item style={{textAlign: "center"}}>
                    <Button style={{background: "#09384D", width: "7.1rem", height: "1.1rem"}}
                            type="submit">登录</Button>
                </Form.Item>
            </Form>
            <div style={{
                display: "flex",
                width: "4.88rem",
                margin: "0 auto",
                justifyContent: "space-between",
                fontSize: "0.34rem",
                color: "white"
            }}>
                <a onClick={this.onCreateAccountClick.bind(this)} style={{color: "white"}}>注册账号</a>
                <a onClick={this.onChangePwdClick.bind(this)} style={{color: "white"}}>修改密码</a>
                <a onClick={this.onRetrivePwdClick.bind(this)} style={{color: "white"}}>找回密码</a>
            </div>
            <div style={{position: "fixed", bottom: "0.3rem", width: "100%"}}>
                <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2024078001号-1</a>
            </div>
        </div>;
    }
}

export default withRouter(Login);
