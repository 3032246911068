import {Component} from "react";
import withRouter, {IRouterProps} from "../../utlis/withRouter";

class Splash extends Component<IRouterProps, any>{
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(()=>{
            this.props.navigate({
                pathname:"/login"
            })
        },3000)
    }

    render() {
        return <div>
            加载页
        </div>;
    }
}
export default withRouter(Splash);
