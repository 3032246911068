import {Component, Fragment} from "react";
import {inject, observer} from "mobx-react";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {Button, Modal, NavBar, Tabs, Toast} from "antd-mobile";
import MapItem from "../../../component/MapItem";
import MapEntity from "../../../models/MapEntity";
import {APIHelper} from "../../../utlis/APIHelper";
import {IStore} from "../../../stores";
import {MapGroup} from "../../../stores/Config";
import {toJS} from "mobx";
import {ModalUtils} from "../../../utlis/modalUtils";

interface MapDetailState {
}

interface MapDetailProps extends IStore, IRouterProps {

}

@inject("player", "config")
@observer
class MapDetail extends Component<MapDetailProps, MapDetailState> {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        ModalUtils.ShowLoading();
        this.props.player.refreshIdleInfo().then(() => {
        }).catch((err) => {
            ModalUtils.ShowFailedMsg(err);
        }).finally(()=>{
            ModalUtils.HideLoading();
        })
    }

    onBattleStart = (map: MapEntity) => {
        const {mapIdleInfo} = this.props.player;
        var promise: Promise<any> = null;
        if (mapIdleInfo && mapIdleInfo.map === map.id) {
            promise = this.props.player.endIdle(map.id).then((res: Array<{ id: string, amount: number }>) => {
                var content = res.map((item) => {
                    return {name: item.id === "10000" ? "经验值" : this.props.config.itemTemplateMap.get(item.id).name, ...item}
                });
                Modal.show({
                    title: "结算成功",
                    closeOnMaskClick: true,
                    content: <Fragment>
                        {
                            content.map((c) => {
                                return <div key={c.id}>{`获得${c.name}:x${c.amount}`}</div>
                            })
                        }
                    </Fragment>
                })
            })
        } else {
            promise = this.props.player.beginIdle(map.id).then(() => {
                ModalUtils.ShowSuccessMsg("开始挂机");
            })
        }

        ModalUtils.ShowLoading();
        promise.then(() => {
            return this.props.player.refreshPlayerInfo();
        }).catch((err) => {
            this.props.player.refreshIdleInfo();
            ModalUtils.ShowFailedMsg(err);
        }).finally(()=>{
            ModalUtils.HideLoading();
        })
    }

    onBattleBoss(map: string) {
        ModalUtils.ShowLoading();
        APIHelper.GetMapBossInfo(map).then((res) => {
            this.props.navigate({pathname: "/main/battle/details"}, {
                state: res.data
            })
        }).finally(()=>{
            ModalUtils.HideLoading();
        })
    }

    renderLevel(maps: MapGroup[], region: string) {
        var index = maps.findIndex((item) => item.name === region);
        index = Math.max(0, index);
        const {mapIdleInfo} = this.props.player;
        return <div className="battle-details-container">
            {
                maps[index].map.subMap.map((map) => {
                    return <MapItem onIdleClick={() => this.onBattleStart(map)} key={map.id}
                                    onBossClick={() => this.onBattleBoss(map.id)}
                                    idleInfo={mapIdleInfo} active={mapIdleInfo && map.id === mapIdleInfo.map}
                                    data={map}></MapItem>
                })
            }
        </div>;
    }


    render() {
        var type = this.props.getQueryParams("type");
        var region = this.props.getQueryParams("region");
        var maps = this.props.config.maps;
        return <div>
            <NavBar onBack={() => this.props.navigate(-1)}
                    style={{height: "0.94rem", background: "#09384D"}}>{region}</NavBar>
            {
                this.renderLevel(maps, region)
            }
        </div>
    }
}

export default withRouter(MapDetail);
