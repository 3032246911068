import {Component, Fragment} from "react";
import "./index.less"
import {Button, Ellipsis, Image, Modal, Radio, Rate} from "antd-mobile";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {IStore} from "../../../stores";
import BackpackItem from "../../../component/BackpackItem";
import {inject, observer} from "mobx-react";
import {GetItemType, ItemTopType, ItemType, ItemTypeList} from "../../../enums/ItemType";
import {CraftingGroup} from "../../../component/BackpackItem/CraftingGroup";
import {APIHelper} from "../../../utlis/APIHelper";
import {PlayerItemEntity} from "../../../models/PlayerItemEntity";
import {GetAssetIcon, GetAssetIconByIcon} from "../../../utlis/AssetsUtils";
import PropertyEntity from "../../../models/PropertyEntity";
import EquipProperty from "../../../component/EquipProperty";
import {ArrowRightOutlined} from "@ant-design/icons";
import {WuXing} from "../../../enums/WuXing";
import ExchangeItem from "../../../component/ExchangeItem";
import ItemEntity from "../../../models/ItemEntity";
import {ModalUtils} from "../../../utlis/modalUtils";

interface BackPackProps extends IRouterProps, IStore {

}

@inject("player", "config", "tabs")
@observer
class Backpack extends Component<BackPackProps, any> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    onLoadEquip(item: PlayerItemEntity) {
        ModalUtils.ShowLoading();
        APIHelper.LoadItem({itemId: item.id}).then(() => {
            return this.props.player.refreshPlayerItems()
        }).then(() => {
            return this.props.player.refreshPlayerInfo()
        }).then(() => {
            ModalUtils.ShowSuccessMsg("装备成功");
        }).catch((err) => {
            ModalUtils.ShowFailedMsg(err);
        }).finally(() => {
            ModalUtils.HideLoading();
        })
    }

    onUnLoadEquip(item: PlayerItemEntity) {
        ModalUtils.ShowLoading();
        APIHelper.UnloadItem({itemId: item.id}).then(() => {
            return this.props.player.refreshPlayerItems()
        }).then(() => {
            return this.props.player.refreshPlayerInfo()
        }).then(() => {
            ModalUtils.ShowSuccessMsg("卸下成功");
        }).catch((err) => {
            ModalUtils.ShowFailedMsg(err);
        }).finally(() => {
            ModalUtils.HideLoading();
        })

    }

    onConfirmUpgrade(item: PlayerItemEntity) {
        ModalUtils.ShowLoading();
        APIHelper.UpgradeItem(item.id).then(() => {
            return this.props.player.refreshPlayerItems()
        }).then(() => {
            return this.props.player.refreshPlayerInfo()
        }).then(() => {
            ModalUtils.ShowSuccessMsg("强化成功");
            Modal.clear();
            this.onEquipUpgradeClick(this.props.player.userPlayerItems.find((i) => i.id === item.id))
        }).catch((err) => {
            ModalUtils.ShowFailedMsg(err);
        }).finally(() => {
            ModalUtils.HideLoading();
        });
    }

    renderItemUpgradeProperty(color, type, origin: PropertyEntity, upgrade: PropertyEntity, scale) {
        var itemType = type as ItemType;

        return <div style={{fontSize: "0.24rem"}}>
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Necklace || itemType === ItemType.Ring || itemType === ItemType.JadePendant || itemType === ItemType.Talisman) &&
                <EquipProperty color={color} title="攻击力" value={origin.attack + upgrade.attack}
                               toValue={Math.floor(origin.attack * scale)}></EquipProperty>
            }
            {
                (itemType === ItemType.Helmet || itemType === ItemType.Cloth || itemType === ItemType.Belt || itemType === ItemType.Bracers || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="防御力" value={origin.defense + upgrade.defense}
                               toValue={Math.floor(origin.defense * scale)}></EquipProperty>
            }
            {
                (itemType === ItemType.Helmet || itemType === ItemType.Cloth || itemType === ItemType.Belt || itemType === ItemType.Bracers || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="生命值" value={origin.health + upgrade.health}
                               toValue={Math.floor(origin.health * scale)}></EquipProperty>

            }
            {
                (itemType === ItemType.Weapon) &&
                <EquipProperty color={color} title="命中率" value={origin.hitRate + upgrade.hitRate}
                               toValue={Math.floor(origin.hitRate * scale)}></EquipProperty>
            }
            {
                (itemType === ItemType.JadePendant) &&
                <EquipProperty color={color} title="闪避率" value={origin.dodgeRate + upgrade.dodgeRate}
                               toValue={Math.floor(origin.dodgeRate * scale)}></EquipProperty>
            }
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Necklace) &&
                <EquipProperty color={color} title="会心率" value={origin.criticalRate + upgrade.criticalRate}
                               toValue={Math.floor(origin.criticalRate * scale)}></EquipProperty>

            }
            {
                (itemType === ItemType.Talisman) &&
                <EquipProperty color={color} title="会伤" value={origin.criticalDamage + upgrade.criticalDamage}
                               toValue={Math.floor(origin.criticalDamage * scale)}></EquipProperty>

            }
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="速度" value={origin.speed + upgrade.speed}
                               toValue={Math.floor(origin.speed * scale)}></EquipProperty>
            }
            {
                (itemType === ItemType.Ring) &&
                <EquipProperty color={color} title="伤害增加" value={origin.damageBoost + upgrade.damageBoost}
                               toValue={Math.floor(origin.damageBoost * scale)}></EquipProperty>

            }
            {
                (itemType === ItemType.Cloth) &&
                <EquipProperty color={color} title="伤害缩小" value={origin.defenseBoost + upgrade.defenseBoost}
                               toValue={Math.floor(origin.defenseBoost * scale)}></EquipProperty>
            }
        </div>
    }

    renderItemProperty(color, type, origin: PropertyEntity, upgrade: PropertyEntity) {
        var itemType = type as ItemType;
        return <div style={{fontSize: "0.24rem"}}>
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Necklace || itemType === ItemType.Ring || itemType === ItemType.JadePendant || itemType === ItemType.Talisman) &&
                <EquipProperty color={color} title="攻击力" value={origin.attack + upgrade.attack}></EquipProperty>
            }
            {
                (itemType === ItemType.Helmet || itemType === ItemType.Cloth || itemType === ItemType.Belt || itemType === ItemType.Bracers || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="防御力" value={origin.defense + upgrade.defense}></EquipProperty>
            }
            {
                (itemType === ItemType.Helmet || itemType === ItemType.Cloth || itemType === ItemType.Belt || itemType === ItemType.Bracers || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="生命值" value={origin.health + upgrade.health}></EquipProperty>

            }
            {
                (itemType === ItemType.Weapon) &&
                <EquipProperty color={color} title="命中率" value={origin.hitRate + upgrade.hitRate}></EquipProperty>
            }
            {
                (itemType === ItemType.JadePendant) &&
                <EquipProperty color={color} title="闪避率"
                               value={origin.dodgeRate + upgrade.dodgeRate}></EquipProperty>
            }
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Necklace) &&
                <EquipProperty color={color} title="会心率"
                               value={origin.criticalRate + upgrade.criticalRate}></EquipProperty>

            }
            {
                (itemType === ItemType.Talisman) &&
                <EquipProperty color={color} title="会伤"
                               value={origin.criticalDamage + upgrade.criticalDamage}></EquipProperty>

            }
            {
                (itemType === ItemType.Weapon || itemType === ItemType.Shoe) &&
                <EquipProperty color={color} title="速度" value={origin.speed + upgrade.speed}></EquipProperty>
            }
            {
                (itemType === ItemType.Ring) &&
                <EquipProperty color={color} title="伤害增加"
                               value={origin.damageBoost + upgrade.damageBoost}></EquipProperty>

            }
            {
                (itemType === ItemType.Cloth) &&
                <EquipProperty color={color} title="伤害缩小"
                               value={origin.defenseBoost + upgrade.defenseBoost}></EquipProperty>
            }
        </div>
    }

    onEquipUpgradeClick(item: PlayerItemEntity) {
        var color = item.rarity === 1 ? "#00EEFF" : (item.rarity === 2 ? "#C800FF" : "#F6C509")
        ModalUtils.ShowLoading();
        const upgradeAble = GetItemType(item.item.type) === ItemTopType.AttackWeapon || GetItemType(item.item.type) === ItemTopType.DefenseWeapon;
        APIHelper.GetItemUpgradeInfo(item.id).then((res) => {
            ModalUtils.HideLoading();
            const config = res.data.config;
            var xuanjing = config ? this.props.config.itemTemplateMap.get(config.item + "") : null;
            Modal.show({
                bodyClassName: upgradeAble && config ? "upgrade-modal" : "info-modal",
                closeOnMaskClick: true,
                header: null,
                content: <div style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden"
                }}>
                    <div className="top-content">
                        <div style={{display: "flex"}}>
                            <div style={{
                                width: "1rem",
                                height: "1rem",
                                padding: "0.05rem",
                                marginBottom: "0.1rem",
                                boxSizing: "border-box",
                                position: "relative",
                                marginRight: "0.1rem",
                                border: `solid 0.05rem ${color}`
                            }}>
                                <img src={GetAssetIconByIcon(item.item.type, item.item.icon, item.item.wuxing)}
                                     style={{width: "0.8rem", height: "0.8rem"}}/>
                                {
                                    GetItemType(item.item.type) !== ItemTopType.Material &&
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        fontSize: "0.24rem"
                                    }}>+{item.level}</div>
                                }

                            </div>
                            <div>
                                {
                                    <div style={{
                                        color: "white",
                                        fontSize: "0.28rem",
                                        height: "0.36rem",
                                        lineHeight: "0.36rem",
                                        marginTop: "0.1rem"
                                    }}>{upgradeAble ? (item.item.name + " +" + item.level) : (item.item.name)}
                                    </div>
                                }
                                {
                                    upgradeAble && config && <div style={{color: "#FBFF00", fontSize: "0.36rem"}}>强化至
                                        <span
                                            style={{
                                                color: "#F6C509",
                                                fontSize: "0.36rem",
                                                marginLeft: "0.1rem",
                                                lineHeight: "0.36rem"
                                            }}>{config.level - 1}</span><ArrowRightOutlined
                                            style={{width: "0.24rem", height: "0.24rem", margin: "0.1rem 0"}}/><span
                                            style={{
                                                color: "#F6C509",
                                                fontSize: "0.36rem",
                                                lineHeight: "0.36rem"
                                            }}>{config.level}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <Rate style={{"--star-size": "0.24rem"}} count={8} value={2}></Rate>
                        {
                            upgradeAble &&
                            <Fragment>
                                {
                                    config && <div>
                                        <div style={{marginTop: "0.1rem"}}>玄晶强化</div>
                                        <div style={{marginTop: "0.1rem", display: "flex"}}>
                                            {
                                                <div style={{
                                                    width: "1rem",
                                                    height: "1rem",

                                                    padding: "0.05rem",
                                                    boxSizing: "border-box",
                                                    position: "relative",
                                                    border: `solid 0.05rem #00EEFF`
                                                }}>
                                                    <Image
                                                        src={GetAssetIconByIcon(xuanjing.type, xuanjing.icon, xuanjing.wuxing)}
                                                        style={{width: "100%", height: "100%"}}/>
                                                </div>
                                            }
                                            {
                                                <div style={{
                                                    width: "1rem",
                                                    height: "1rem",
                                                    padding: "0.05rem",
                                                    boxSizing: "border-box",
                                                    marginLeft: "0.1rem",
                                                    position: "relative",
                                                    border: `solid 0.05rem #00EEFF`
                                                }}>
                                                    <Image src="/images/ui/coins_icon.png"
                                                           style={{width: "100%", height: "100%"}}/>
                                                </div>
                                            }
                                        </div>
                                        <div style={{marginTop: "0.1rem", fontSize: "0.28rem"}}>消耗资源</div>
                                        <div style={{
                                            marginTop: "0.1rem",
                                            fontSize: "0.28rem"
                                        }}>{xuanjing.name}*{config.itemAmount}</div>
                                        <div style={{
                                            marginTop: "0.1rem",
                                            color: "#F6C509",
                                            fontSize: "0.28rem"
                                        }}>银钱*{config.coins}</div>
                                    </div>
                                }
                                <div style={{marginTop: "0.1rem"}}>基础属性</div>
                                {
                                    config ? this.renderItemUpgradeProperty(color, item.item.type, res.data.result.property, res.data.result.upgradeProperty, config.property / 1000) :
                                        this.renderItemProperty(color, item.item.type, res.data.result.upgradeProperty, res.data.result.upgradeProperty)
                                }
                                {this.renderEquipLevelLockProperty(item)}
                            </Fragment>}

                    </div>
                    <div style={{
                        height: "0.8rem",
                        width: "5.28rem",
                        display: "flex",
                        boxSizing: "border-box",
                        color
                    }}>
                        {
                            item.level < 16 && (GetItemType(item.item.type) === ItemTopType.AttackWeapon || GetItemType(item.item.type) === ItemTopType.DefenseWeapon) &&
                            <a onClick={() => {
                                this.onConfirmUpgrade(item)
                            }
                            } style={{
                                flex: 1,
                                borderTop: "solid 1px #787332",
                                color: "white",
                                borderRight: "solid 1px #787332",
                                fontSize: "0.36rem",
                                fontWeight: "600",
                                textAlign: "center",
                                lineHeight: "0.8rem"
                            }}>强化</a>
                        }
                        <a onClick={Modal.clear} style={{
                            flex: 1,
                            borderTop: "solid 1px #787332",
                            color: "white",
                            fontSize: "0.36rem",
                            fontWeight: "600",
                            textAlign: "center",
                            lineHeight: "0.8rem"
                        }}>关闭</a>
                    </div>
                </div>
            })
        }).catch((err) => {
            ModalUtils.ShowFailedMsg(err);
        })

    }

    renderEquipLevelLockProperty(item: PlayerItemEntity) {
        const itemTemp = this.props.config.itemTemplateMap.get(item.item.id);
        console.log(itemTemp);
        itemTemp.levels.sort((a, b) => a.unlockLevel - b.unlockLevel);
        if (!itemTemp.levels || itemTemp.levels.length == 0) {
            return null;
        }
        return <Fragment>
            <div style={{marginTop: "0.1rem"}}>强化({item.level}/16)</div>
            {
                itemTemp.levels.map((level) => {
                    let property = "";
                    if (level.property.attack > 0) {
                        property += `攻击+${level.property.attack},`;
                    }
                    if (level.property.health > 0) {
                        property += `生命+${level.property.health},`;

                    }
                    if (level.property.defense > 0) {
                        property += `防御+${level.property.defense},`;

                    }
                    if (level.property.hitRate > 0) {
                        property += `命中+${level.property.hitRate},`;

                    }
                    if (level.property.dodgeRate > 0) {
                        property += `闪避+${level.property.dodgeRate},`;

                    }
                    if (level.property.speed > 0) {
                        property += `速度+${level.property.speed},`;

                    }
                    if (level.property.criticalDamage > 0) {
                        property += `会伤+${level.property.criticalDamage},`;

                    }
                    if (level.property.criticalRate > 0) {
                        property += `会心+${level.property.criticalRate},`;

                    }
                    if (level.property.damageBoost > 0) {
                        property += `伤害增强+${level.property.damageBoost},`;

                    }
                    if (level.property.defenseBoost > 0) {
                        property += `防御增强+${level.property.defenseBoost},`;

                    }
                    if (level.property.wuxingPositive > 0) {
                        property += `五行增益+${level.property.wuxingPositive},`;

                    }
                    if (level.property.wuxingNegative > 0) {
                        property += `五行减益+${level.property.wuxingNegative},`;
                    }
                    if (property.length>0){
                        property = property.substring(0,property.length -1);
                    }
                    else {
                        return null;
                    }
                    return <div style={{display: "flex", fontSize: "0.24rem"}}>
                        <div
                            style={{color: item.level >= level.unlockLevel ? "#FBFF00" : "#00FF00"}}>(+{level.unlockLevel})
                        </div>
                        <div style={{color: item.level >= level.unlockLevel ? "#FBFF00" : "#00FF00"}}>{property}</div>
                    </div>
                })
            }
        </Fragment>
    }

    onDestroyEquip(item: PlayerItemEntity) {
        ModalUtils.ShowConfirm("提示", "是否要分解该装备?", () => {
            ModalUtils.ShowLoading();
            APIHelper.DestroyItem(item.id).then((res: Array<{ id: string, amount: number }>) => {
                var content = res.map((item) => {
                    return {name: item.id === "coins" ? "银币" : this.props.config.itemTemplateMap.get(item.id).name, ...item}
                });
                Modal.show({
                    title: "分解成功",
                    closeOnMaskClick: true,
                    content: <Fragment>
                        {
                            content.map((c) => {
                                return <div key={c.id}>{`获得${c.name}:x${c.amount}`}</div>
                            })
                        }
                    </Fragment>
                })
                return this.props.player.refreshPlayerInfo()
            }).then(() => {
                return this.props.player.refreshPlayerItems()
            }).catch((err) => {
                ModalUtils.ShowFailedMsg(err);
            }).finally(() => {
                ModalUtils.HideLoading();
            })
        })

    }

    renderEquipment() {
        const {player} = this.props;
        const itemTypes = ItemTypeList();
        return <div style={{display: "flex", flexDirection: "column", overflow: "hidden"}}>
            <div className="quality-filter">
                <div className="filter-title">物品品质</div>
                <Radio.Group value={this.props.tabs.backpack.currentFilter}
                             onChange={(v) => this.props.tabs.backpack.UpdateFilter(v + "")}>
                    <Radio value="all" key="all">全部</Radio>
                    <Radio value="0" key="0">白色</Radio>
                    <Radio value="1" key="1">蓝色</Radio>
                    <Radio value="2" key="2">紫色</Radio>
                    <Radio value="3" key="3">橙色</Radio>
                </Radio.Group>
            </div>
            <div className="item-list">
                {
                    player.userPlayerItems.map((item) => {
                        if (this.props.tabs.backpack.currentFilter === "all") {

                        } else if (this.props.tabs.backpack.currentFilter === "0") {
                            if (GetItemType(item.item.type) !== ItemTopType.Material) {
                                return null;
                            }
                        } else {
                            if (GetItemType(item.item.type) === ItemTopType.Material || parseInt(this.props.tabs.backpack.currentFilter) !== item.rarity) {
                                return null;
                            }
                        }

                        return <BackpackItem onUpgrade={this.onEquipUpgradeClick.bind(this)}
                                             onUnLoad={this.onUnLoadEquip.bind(this)}
                                             onLoad={this.onLoadEquip.bind(this)}
                                             onDestroy={this.onDestroyEquip.bind(this)} data={item}
                                             key={item.id}></BackpackItem>
                    })
                }
            </div>
        </div>
    }

    doExchange(item: ItemEntity) {
        ModalUtils.ShowLoading();
        APIHelper.ExchangeItem({itemId: item.id, amount: 1}).then(() => {
            return this.props.player.refreshPlayerItems()
        }).then(() => {
            ModalUtils.ShowSuccessMsg("合成成功");

        }).catch((err) => {
            ModalUtils.ShowFailedMsg(err);

        }).finally(() => {
            ModalUtils.HideLoading();
        });
    }

    onEquipExchangeClick(item: ItemEntity) {
        var color = "#F6C509"
        Modal.show({
            bodyClassName: "exchange-modal",
            closeOnMaskClick: true,
            header: null,
            content: <div style={{position: "absolute", width: "100%", height: "100%"}}>
                <div className="top-content">
                    <div>兑换</div>
                    <Fragment>
                        <div className="material-box">
                            <div style={{display: "flex", justifyContent: "space-around", marginTop: "0.32rem"}}>
                                {
                                    item.materials.map((item, index) => {
                                        var material = this.props.config.itemTemplateMap.get(item.item)
                                        return <div key={index} className="material-item">
                                            <img style={{width: "100%", height: "100%"}}
                                                 src={GetAssetIconByIcon(material.type, material.icon, material.wuxing)}/>
                                            <div style={{
                                                position: "absolute",
                                                top: "1.02rem",
                                                fontSize: "0.2rem",
                                                textAlign: "center",
                                                width: "1.5rem",
                                                left: "-0.25rem"
                                            }}>
                                                {material.name}*{item.amount}
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <div style={{display: "flex", justifyContent: "center", marginTop: "1rem"}}>
                                <div className="material-item">
                                    {

                                        <img style={{width: "100%", height: "100%"}}
                                             src={GetAssetIconByIcon(item.type, item.icon, item.wuxing)}/>
                                    }
                                    <div style={{
                                        position: "absolute",
                                        height: "0.5rem",
                                        lineHeight: "0.5rem",
                                        top: "1.02rem",
                                        left: "-1rem",
                                        background: "#2C4F62",
                                        fontSize: "0.24rem",
                                        textAlign: "center",
                                        width: "3rem"
                                    }}>
                                        {item && item.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </div>
                <div style={{
                    height: "0.8rem",
                    position: "absolute",
                    bottom: "3px",
                    width: "5.28rem",
                    display: "flex",
                    boxSizing: "border-box",
                    color
                }}>
                    {
                        <a onClick={() => {
                            this.doExchange(item);
                        }} style={{
                            flex: 1,
                            borderTop: "solid 1px #787332",
                            color: "white",
                            borderRight: "solid 1px #787332",
                            fontSize: "0.36rem",
                            fontWeight: "600",
                            textAlign: "center",
                            lineHeight: "0.8rem"
                        }}>兑换</a>
                    }
                    <a onClick={Modal.clear} style={{
                        flex: 1,
                        borderTop: "solid 1px #787332",
                        color: "white",
                        fontSize: "0.36rem",
                        fontWeight: "600",
                        textAlign: "center",
                        lineHeight: "0.8rem"
                    }}>关闭</a>
                </div>
            </div>
        })

    }

    renderSpecialExchange() {
        const {itemTemplates} = this.props.config;
        const items = itemTemplates.filter((item) => item.special === 1 && (item.type !== ItemType.Weapon || (item.type === ItemType.Weapon && item.wuxing === this.props.player.currentPlayer.wuxing)))
        items.sort((a, b) => a.type - b.type);
        return <div className="backpack-details-container">
            <div className="item-list">
                {
                    items.map((item) => {
                        return <ExchangeItem onExchange={(i) => {
                            this.onEquipExchangeClick(i);
                        }}
                                             selected={false} key={item.id}
                                             data={item}></ExchangeItem>
                    })
                }
            </div>
        </div>;
    }

    renderCrafting() {
        const {itemTemplates} = this.props.config;

        return <div className="backpack-details-container">
            <div className="item-list">
                {
                    itemTemplates.filter((item) => item.type === 1 && item.requireLevel <= 100 && item.wuxing === this.props.player.currentPlayer.wuxing).map((item) => {
                        return <CraftingGroup key={item.id} onGoCraft={(data) => {
                            this.props.navigate({
                                pathname: "/main/backpack/craftEquip",
                                search: "level=" + data.requireLevel
                            })
                        }} data={item}></CraftingGroup>
                    })
                }
            </div>
        </div>;
    }

    renderCraftingMaterial() {
        const {itemTemplates} = this.props.config;

        return <div className="backpack-details-container">
            <div className="item-list">
                <div style={{
                    width: "7.26rem",
                    height: "1.12rem",
                    margin: "1px 0 0",
                    display: "flex",
                    background: "#3B7082",
                    padding: "0.06rem",
                    boxSizing: "border-box"
                }}>
                    <div style={{
                        width: "1rem",
                        height: "1rem",
                        padding: "0.05rem",
                        boxSizing: "border-box",
                        position: "relative",
                        border: "solid 0.05rem #F6C509"
                    }}>
                        <Image src={GetAssetIcon(ItemType.XuanJing, 1, WuXing.Other)}
                               style={{width: "100%", height: "100%"}}/>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "4.5rem",
                        textAlign: "left",
                        margin: "0 0.12rem"
                    }}>
                        <div style={{fontSize: "0.24rem"}}>玄晶</div>
                        <Ellipsis style={{fontSize: "0.2rem"}} direction='end' rows={2} content="玄晶"/>
                    </div>
                    <Button onClick={() => {
                        this.props.navigate({
                            pathname: "/main/backpack/craftItem",
                            search: "type=" + ItemType.XuanJing
                        })
                    }} style={{
                        width: "1.8rem",
                        height: "0.64rem",
                        padding: "0",
                        fontSize: "0.28rem",
                        lineHeight: "0.64rem",
                        margin: "auto 0.12rem"
                    }}>前往合成</Button>
                </div>
                <div style={{
                    width: "7.26rem",
                    height: "1.12rem",
                    margin: "1px 0 0",
                    display: "flex",
                    background: "#3B7082",
                    padding: "0.06rem",
                    boxSizing: "border-box"
                }}>
                    <div style={{
                        width: "1rem",
                        height: "1rem",
                        padding: "0.05rem",
                        boxSizing: "border-box",
                        position: "relative",
                        border: "solid 0.05rem #F6C509"
                    }}>
                        <Image src={GetAssetIcon(ItemType.Mineral, 1, WuXing.Other)}
                               style={{width: "100%", height: "100%"}}/>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "4.5rem",
                        textAlign: "left",
                        margin: "0 0.12rem"
                    }}>
                        <div style={{fontSize: "0.24rem"}}>矿石</div>
                        <Ellipsis style={{fontSize: "0.2rem"}} direction='end' rows={2} content="矿石"/>
                    </div>
                    <Button onClick={() => {
                        this.props.navigate({
                            pathname: "/main/backpack/craftItem",
                            search: "type=" + ItemType.Mineral
                        })
                    }} style={{
                        width: "1.8rem",
                        height: "0.64rem",
                        padding: "0",
                        fontSize: "0.28rem",
                        lineHeight: "0.64rem",
                        margin: "auto 0.12rem"
                    }}>前往合成</Button>
                </div>
                <div style={{
                    width: "7.26rem",
                    height: "1.12rem",
                    margin: "1px 0 0",
                    display: "flex",
                    background: "#3B7082",
                    padding: "0.06rem",
                    boxSizing: "border-box"
                }}>
                    <div style={{
                        width: "1rem",
                        height: "1rem",
                        padding: "0.05rem",
                        boxSizing: "border-box",
                        position: "relative",
                        border: "solid 0.05rem #F6C509"
                    }}>
                        <Image src={GetAssetIcon(ItemType.Clothes, 1, WuXing.Other)}
                               style={{width: "100%", height: "100%"}}/>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "4.5rem",
                        textAlign: "left",
                        margin: "0 0.12rem"
                    }}>
                        <div style={{fontSize: "0.24rem"}}>布料</div>
                        <Ellipsis style={{fontSize: "0.2rem"}} direction='end' rows={2} content="布料"/>
                    </div>
                    <Button onClick={() => {
                        this.props.navigate({
                            pathname: "/main/backpack/craftItem",
                            search: "type=" + ItemType.Clothes
                        })
                    }} style={{
                        width: "1.8rem",
                        height: "0.64rem",
                        padding: "0",
                        fontSize: "0.28rem",
                        lineHeight: "0.64rem",
                        margin: "auto 0.12rem"
                    }}>前往合成</Button>
                </div>
            </div>
        </div>;
    }

    render() {
        const {backpack} = this.props.tabs;
        const itemTypes = ItemTypeList();
        return <div className="backpack-details-container">
            <div className="backpack-tabs">
                <Button onClick={() => backpack.UpdateCurrentTab("equipment")}
                        className={`backpack-tab-item ${backpack.currentTab === "equipment" ? "active" : ""}`}
                        key="equipment">物品</Button>
                <Button onClick={() => backpack.UpdateCurrentTab("crafting")}
                        className={`backpack-tab-item ${backpack.currentTab === "crafting" ? "active" : ""}`}
                        key="crafting">装备合成</Button>
                <Button onClick={() => backpack.UpdateCurrentTab("material")}
                        className={`backpack-tab-item ${backpack.currentTab === "material" ? "active" : ""}`}
                        key="material">材料合成</Button>
                <Button onClick={() => backpack.UpdateCurrentTab("special")}
                        className={`backpack-tab-item ${backpack.currentTab === "special" ? "active" : ""}`}
                        key="special">特殊装备</Button>
            </div>
            {
                backpack.currentTab === "equipment" && this.renderEquipment()
            }
            {
                backpack.currentTab === "crafting" && this.renderCrafting()
            }
            {
                backpack.currentTab === "material" && this.renderCraftingMaterial()
            }
            {
                backpack.currentTab === "special" && this.renderSpecialExchange()
            }
        </div>;
    }
}

export default withRouter(Backpack);
