import {ItemType} from "../enums/ItemType";
import {WuXing} from "../enums/WuXing";

const AssetsRoot = "/images/icons"

export function GetPlayerIcon(wuxing: WuXing) {
    var assets = ""
    switch (wuxing) {
        case WuXing.Gold:
            assets = "faction_tianwang.png"
            break;
        case WuXing.Wood:
            assets = "faction_tangmen.png"
            break;
        case WuXing.Water:
            assets = "faction_duanshi.png"
            break;
        case WuXing.Fire:
            assets = "faction_gaibang.png"
            break;
        case WuXing.Earth:
            assets = "faction_kunlun.png"
            break;
    }
    return `${AssetsRoot}/00_School/${assets}`
}


export function GetAssetIcon(itemType: ItemType, level: number, wuxing: WuXing = null) {
    var assets = ""
    var typeDir = ""
    var type = ("00" + itemType.valueOf()).substr(-2);
    var levStr = ("000" + level).substr(-3);
    switch (itemType) {
        case ItemType.Weapon:
            switch (wuxing) {
                case WuXing.Gold:
                    assets = "Gold"
                    break;
                case WuXing.Wood:
                    assets = "Wood"
                    break;
                case WuXing.Water:
                    assets = "Water"
                    break;
                case WuXing.Fire:
                    assets = "Fire"
                    break;
                case WuXing.Earth:
                    assets = "Earth"
                    break;
            }
            typeDir = "01_Weapon/" + assets;
            break;
        case ItemType.Necklace:
            typeDir = "02_Necklace";
            break;
        case ItemType.Ring:
            typeDir = "03_Ring";
            break;
        case ItemType.JadePendant:
            typeDir = "04_JadePendant";
            break;
        case ItemType.Talisman:
            typeDir = "05_Talisman";
            break;
        case ItemType.Helmet:
            typeDir = "06_Helmet";
            break;
        case ItemType.Cloth:
            typeDir = "07_Cloth";
            break;
        case ItemType.Belt:
            typeDir = "08_Belt";
            break;
        case ItemType.Bracers:
            typeDir = "09_Bracers";
            break;
        case ItemType.Shoe:
            typeDir = "10_Shoe";
            break;
        case ItemType.Cloak:
            typeDir = "11_Cloak";
            levStr = "001";
            break;
        case ItemType.LevelSheet:
            typeDir = "12_LevelSheet";
            levStr = "001";
            break;
        case ItemType.WuxingSheet:
            typeDir = "13_WuxingSheet";
            levStr = "001";
            break;
        case ItemType.XuanJing:
            typeDir = "14_XuanJing";
            break;
        case ItemType.Mineral:
            typeDir = "15_Mineral";
            break;
        case ItemType.Clothes:
            typeDir = "16_Clothes";
            break;
        case ItemType.Other:
            typeDir = "17_Other";
            break;
        case ItemType.Special:
            typeDir = "99_Special";
            break;
    }
    return `${AssetsRoot}/${typeDir}/${type}${wuxing.valueOf()}${levStr}.png`
}



export function GetAssetIconByIcon(itemType: ItemType, icon: string, wuxing: WuXing = null) {
    var assets = ""
    var typeDir = ""
    switch (itemType) {
        case ItemType.Weapon:
            switch (wuxing) {
                case WuXing.Gold:
                    assets = "Gold"
                    break;
                case WuXing.Wood:
                    assets = "Wood"
                    break;
                case WuXing.Water:
                    assets = "Water"
                    break;
                case WuXing.Fire:
                    assets = "Fire"
                    break;
                case WuXing.Earth:
                    assets = "Earth"
                    break;
            }
            typeDir = "01_Weapon/" + assets;
            break;
        case ItemType.Necklace:
            typeDir = "02_Necklace";
            break;
        case ItemType.Ring:
            typeDir = "03_Ring";
            break;
        case ItemType.JadePendant:
            typeDir = "04_JadePendant";
            break;
        case ItemType.Talisman:
            typeDir = "05_Talisman";
            break;
        case ItemType.Helmet:
            typeDir = "06_Helmet";
            break;
        case ItemType.Cloth:
            typeDir = "07_Cloth";
            break;
        case ItemType.Belt:
            typeDir = "08_Belt";
            break;
        case ItemType.Bracers:
            typeDir = "09_Bracers";
            break;
        case ItemType.Shoe:
            typeDir = "10_Shoe";
            break;
        case ItemType.Cloak:
            typeDir = "11_Cloak";
            break;
        case ItemType.LevelSheet:
            typeDir = "12_LevelSheet";
            break;
        case ItemType.WuxingSheet:
            typeDir = "13_WuxingSheet";
            break;
        case ItemType.XuanJing:
            typeDir = "14_XuanJing";
            break;
        case ItemType.Mineral:
            typeDir = "15_Mineral";
            break;
        case ItemType.Clothes:
            typeDir = "16_Clothes";
            break;
        case ItemType.Other:
            typeDir = "17_Other";
            break;
        case ItemType.Special:
            typeDir = "99_Special";
            break;
    }
    return `${AssetsRoot}/${typeDir}/${icon}`
}


