import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './thame.less'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "mobx-react";
import stores from "./stores";
import {BrowserRouter} from "react-router-dom";


(function (doc, win) {
    var docEl = doc.documentElement;
    var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
    var recalc = function () {
        var clientWidth = window.innerWidth;
        if (!clientWidth) return;
        docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
    };
    var getStyle = (obj, attr) => {
        if (obj.currentStyle) {
            return obj.currentStyle[attr];
        } else {
            return getComputedStyle(obj, "false")[attr];
        }
    }
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
}(document, window));

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
stores.config.Init().then(() => {
    root.render(
        <Provider {...stores}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    );


    // If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
})


