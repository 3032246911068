import {action, makeAutoObservable, toJS} from "mobx";
import MapEntity from "../models/MapEntity";
import SchoolEntity from "../models/SchoolEntity";
import {APIHelper} from "../utlis/APIHelper";
import {ServerEntity} from "../models/ServerEntity";
import localforage from "localforage";
import ItemEntity from "../models/ItemEntity";
import SkillEntity from "../models/SkillEntity";
import {ActivePointRewardEntity} from "../models/ActivePointRewardEntity";
import {TerritoryArea} from "../models/TerritoryArea";
import {ProductEntity} from "../models/ProductEntity";
import {ArchiveEntity} from "../models/ArchiveEntity";
import PlayerArchiveEntity from "../models/PlayerArchiveEntity";

export interface MapGroup {
    name: string;
    minLevelRequire: number;
    maxLevelRequire: number;
    map: MapEntity;
}
export interface VipConfig {
    level:number;
    requirePoints:number;
    battleClaimScale: number;
    territoryClaimScale: number;
    idleClaimScale: number;
    dungeonClaimScale: number;
    songjinClaimScale: number;
    menpaiClaimScale: number;
    dailyTaskClaimScale:number;
};
export class Config {
    constructor() {
        makeAutoObservable(this);
    }

    public async Init() {
        var configs = await APIHelper.GetGameConfigs();
        var maps = configs.gameMaps as Array<MapEntity>;
        var skills = configs.skills as Array<SkillEntity>;
        var servers = await APIHelper.ServerList();
        this.currentServer = servers[0];
        this.servers = servers;
        maps.forEach((map) => {
            if (map.mapType === 4) {
                this.dungeons.push({
                    name: map.regionName,
                    map: map,
                    maxLevelRequire: map.maxRequireLevel,
                    minLevelRequire: map.minRequireLevel
                });
            } else {
                this.maps.push({
                    name: map.regionName,
                    map: map,
                    maxLevelRequire: map.maxRequireLevel,
                    minLevelRequire: map.minRequireLevel
                });
                this.maps.sort((a, b) => a.minLevelRequire - b.minLevelRequire)
            }

        });
        skills.map((item) => {
            item.activeEffects.sort((i) => i.attribute === "damage" ? -1 : 1)
            this.gameSkillMap.set(item.id, item);
        })
        this.school = configs.schools;
        this.rewards = configs.rewards;
        this.archives = configs.archives;
        this.vipConfigs = configs.vip;
        this.currentServer = await localforage.getItem("LastEnterServer");
        this.itemTemplates = await APIHelper.ItemTemplateList();
        this.itemTemplates.forEach((i) => this.itemTemplateMap.set(i.id, i));
        this.products = (await APIHelper.ListProduct()).data;

    }

    vipConfigs:Array<VipConfig> = [];
    maps: Array<MapGroup> = [];
    dungeons: Array<MapGroup> = [];
    archives: Array<ArchiveEntity> = [];
    servers: Array<ServerEntity> = []
    school: Array<SchoolEntity> = []
    products: Array<ProductEntity> = [];
    currentServer: ServerEntity;
    itemTemplates: Array<ItemEntity> = []
    rewards: Array<ActivePointRewardEntity> = []
    itemTemplateMap: Map<string, ItemEntity> = new Map<string, ItemEntity>();
    gameSkillMap: Map<string, SkillEntity> = new Map<string, SkillEntity>();
    territories: Array<TerritoryArea> = new Array<TerritoryArea>();

    @action
    async updateServer(server: ServerEntity) {
        this.currentServer = server;
        var obj = null;
        if (server) {
            await localforage.setItem("LastEnterServer", toJS(server));
        } else {
            await localforage.removeItem("LastEnterServer");

        }
    }

    @action refreshTerritory() {
        return APIHelper.GetTerritoryList().then((res) => {
            this.territories = res.data;
        });
    }
}
