import {Component} from "react";
import {Avatar} from "antd-mobile";

interface CharacterItemState {
    selected: boolean
}
interface CharacterItemProps{
    selected:boolean
    onSelected:Function;
}
export class CharacterItem extends Component<CharacterItemProps, CharacterItemState> {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected
        }
    }

    shouldComponentUpdate(nextProps: Readonly<CharacterItemProps>, nextState: Readonly<CharacterItemState>, nextContext: any): boolean {
        if (nextProps.selected !== this.state.selected){
            this.setState({selected: nextProps.selected});
            return true;
        }
        return false;
    }


    render() {
        return <div onClick={() => {
            if (!this.state.selected)
                this.setState({selected: !this.state.selected});
            this.props?.onSelected()
        }} style={{width: "1.36rem", height: "1.36rem", position: "relative"}}>
            <Avatar style={{width: "1.36rem", height: "1.36rem"}}
                    src="https://mh.uuuyx.com/client/head/12.png"></Avatar>
            <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                border: this.state.selected ? "0.04rem solid #FFFF00" : "0.04rem solid #FFFFFF",
                top: 0,
                left: 0
            }}></div>
        </div>
    }
}
