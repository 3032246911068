import {Component} from "react";
import {Button, Ellipsis, Image} from "antd-mobile";
import SkillEntity from "../../models/SkillEntity";
import MapEntity from "../../models/MapEntity";
import './index.less'
import MapIdleEntity from "../../models/MapIdleEntity";

interface MapItemProps {
    data: MapEntity;
    active: boolean;
    onIdleClick: Function;
    onBossClick: Function;
    idleInfo: MapIdleEntity
}

interface MapItemState {
    battleMaster: string
}

export default class MapItem extends Component<MapItemProps, MapItemState> {
    simuTimer: NodeJS.Timer;

    constructor(props) {
        super(props);
        this.state = {
            battleMaster: ""
        }
    }

    shouldComponentUpdate(nextProps: Readonly<MapItemProps>, nextState: Readonly<any>, nextContext: any): boolean {
        if(nextProps.active){
            setTimeout(() => this.randomSimuBattle())
        }
        if (nextProps.active && !this.props.active) {
            console.log(1111)
            // this.randomSimuBattle();
            this.simuTimer && clearInterval(this.simuTimer)
            this.simuTimer = setInterval(this.randomSimuBattle.bind(this), 10000)
        } else {
            if (this.props.active) {
                this.simuTimer && clearInterval(this.simuTimer)
            }
        }
        return true;
    }

    randomSimuBattle = function () {
        this.setState({
            battleMaster: this.props.data.masters.find((item) => item.boss === 0).master.name
        })
    }
    render() {
        let {data, active} = this.props;
        return <div className={active ? "map-item active" : "map-item"}>
            <div className="map-item-top">
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "4.5rem",
                    textAlign: "left",
                    margin: "0 0.12rem"
                }}>
                    <div className="title">{data.name}</div>
                    <Ellipsis className="description" direction='end' rows={2}
                              content={`适合Lv.${data.minRequireLevel}级-${data.maxRequireLevel}级`}/>
                </div>
                <Button style={{
                    width: "0.8rem",
                    height: "0.64rem",
                    padding: "0",
                    fontSize: "0.28rem",
                    lineHeight: "0.64rem",
                    margin: "auto 0.12rem"
                }}>查看</Button>
                {
                    active ? <Button onClick={() => this.props.onIdleClick()} style={{
                        width: "0.8rem",
                        height: "0.64rem",
                        padding: "0",
                        fontSize: "0.28rem",
                        lineHeight: "0.64rem",
                        margin: "auto 0.12rem"
                    }}>结算</Button> : <Button onClick={() => this.props.onIdleClick()} style={{
                        width: "0.8rem",
                        height: "0.64rem",
                        padding: "0",
                        fontSize: "0.28rem",
                        lineHeight: "0.64rem",
                        margin: "auto 0.12rem"
                    }}>挂机</Button>
                }
                <Button onClick={() => this.props.onBossClick()} style={{
                    width: "0.8rem",
                    height: "0.64rem",
                    padding: "0",
                    fontSize: "0.28rem",
                    lineHeight: "0.64rem",
                    margin: "auto 0.12rem"
                }}>缉盗</Button>
            </div>
            {
                active && <div className="battle-info">
                <span><span style={{color: "#FFFF00"}}>⌈战斗⌋</span> 正在与<span
                    style={{color: "#FF0000"}}> {this.state.battleMaster} </span>战斗中...</span>
                </div>
            }

            {
                active && <div className="idle-info">
                    <span>挂机时间：{this.props.idleInfo.startTime}</span> <span style={{color: "#FFCC00"}}></span>
                </div>
            }
        </div>;
    }
}
