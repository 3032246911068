import {Component} from "react";
import withRouter, {IRouterProps} from "../../utlis/withRouter";
import {Button, Form, Input, List, NavBar, Toast} from "antd-mobile";
import {ServerEntity} from "../../models/ServerEntity";
import ServerItem from "../../component/ServerItem/ServerItem";
import './index.less'
import {CharacterItem} from "../../component/CharacterItem";
import {APIHelper} from "../../utlis/APIHelper";
import {IStore} from "../../stores";
import {inject, observer} from "mobx-react";
import SchoolEntity from "../../models/SchoolEntity";
import {FormInstance} from "antd-mobile/es/components/form";
import {ModalUtils} from "../../utlis/modalUtils";

interface CharacterCreateState {
    selectCharacterId: string;
    currentSelectDescription:string;
}

interface CharacterCreateProps extends IRouterProps, IStore {

}

@inject("player", "config")
@observer
class CharacterCreate extends Component<CharacterCreateProps, CharacterCreateState> {
    constructor(props) {
        super(props);
        this.state = {
            selectCharacterId: "1",
            currentSelectDescription:""
        }
    }

    private formInstance:FormInstance;

    componentDidMount() {
        this.onSelectCharacter(this.props.config.school[0])
    }

    private onSelectCharacter(character: SchoolEntity) {
        this.formInstance.setFieldValue("role",character.name)
        this.setState({selectCharacterId: character.id,currentSelectDescription:character.description})
    }

    private createNewPlayer(values: any) {
        ModalUtils.ShowLoading();
        APIHelper.CreateNewPlayer({
            schoolId: this.state.selectCharacterId,
            serverId: this.props.config.currentServer.id, ...values
        }).then(() => {
            return this.props.player.Init();
        }).then(() => {
            this.props.navigate({
                pathname: "/main"
            })
        }).catch((reason)=>{
            ModalUtils.ShowFailedMsg(reason);
        }).finally(()=>{
            ModalUtils.HideLoading();
        })

    }

    render() {
        return <div>
            <NavBar back={null} style={{
                background: "var(--custom-navbar-background)",
                fontSize: "0.34rem"
            }} right={
                <a className="game-notice">公告</a>
            }>角色创建</NavBar>
            <div className="character-container">
                <Button onClick={()=>this.props.navigate(-1)} className="back-btn">返回</Button>
                <div className="character-items">
                    {
                        this.props.config.school.map((item) => {
                            return <CharacterItem key={item.id} onSelected={() => this.onSelectCharacter(item)}
                                                  selected={this.state.selectCharacterId === item.id}></CharacterItem>
                        })
                    }
                </div>
                <div className="character-info-container">
                    <Form ref={(ref)=>this.formInstance=ref} onFinish={(values) => this.createNewPlayer(values)} className="character-container-form"
                          style={{border: "none!important"}}>
                        <Form.Item label='角色' name='role'>
                            <Input readOnly placeholder='请选择一个角色' clearable/>
                        </Form.Item>
                        <Form.Item style={{fontSize: "0.28rem", color: "#FBFF00"}}>
                            {
                                this.state.currentSelectDescription
                            }
                        </Form.Item>
                        <Form.Item label='用户名' name='username'>
                            <Input placeholder='请输入用户名' clearable/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="submit">进入游戏</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(CharacterCreate);
