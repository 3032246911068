import {Component} from "react";
import {inject, observer} from "mobx-react";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {IStore} from "../../../stores";
import {Avatar, Button, NavBar, ProgressBar} from "antd-mobile";
import {GetPlayerIcon} from "../../../utlis/AssetsUtils";
import {WuXing} from "../../../enums/WuXing";
import {APIHelper} from "../../../utlis/APIHelper";
import {ModalUtils} from "../../../utlis/modalUtils";
import {BattleStatus} from "../../../enums/battleStatus";
import DoBattle = APIHelper.DoBattle;

interface MapBossBattleProps extends IRouterProps, IStore {

}

interface MapBossBattleState {
    masterName: string;
    masterIcon: string;
    masterTotalHp: number;
    masterCurrentHp: number;
    playerCurrentHp: number;
    playerTotalHp: number;
    logcat: Array<{
        selfHP: number;
        targetHP: number;
        log: string;
    }>;
    renderLog: Array<{
        selfHP: number;
        targetHP: number;
        log: string;
    }>
    map: string;
    drops: string;
    battleWin: boolean
    battleStatus: BattleStatus;

}

@inject("player", "config")
@observer
class MapBossBattle extends Component<MapBossBattleProps, MapBossBattleState> {
    constructor(props: MapBossBattleProps) {
        super(props);
        this.state = {
            masterName: "",
            masterTotalHp: 0,
            playerCurrentHp: 0,
            playerTotalHp: 0,
            masterCurrentHp: 0,
            masterIcon: "",
            logcat: [],
            renderLog: [],
            map: props.location.state.map.id,
            drops: "",
            battleWin: false,
            battleStatus: BattleStatus.Prepare
        }
    }

    componentDidMount() {
        console.log(this.props.location.state)
        // var gameMap: MapEntity = this.props.location.state;
        var master = this.props.location.state.master;
        this.setState({
            masterName: master.name,
            masterIcon: master.icon,
            masterCurrentHp: master.property.health,
            masterTotalHp: master.property.health,
            playerTotalHp: this.props.player.equipmentProperty.health + this.props.player.currentPlayer.property.health,
            playerCurrentHp: this.props.player.equipmentProperty.health + this.props.player.currentPlayer.property.health
        })
    }

    doBattle = async () => {
        this.setState({
            logcat: [],
            renderLog: [],
            drops: "",
            battleWin: false,
            battleStatus: BattleStatus.DuringBattle
        })
        DoBattle({mapId: this.state.map}).then((res) => {
            var drops = res.data.drops as Array<any>;
            var content = drops.map((item) => {
                return `${item.id === "10000" ? "经验值" : this.props.config.itemTemplateMap.get(item.id).name}x${item.amount}`
            });
            this.setState({
                logcat: res.data.logcat,
                renderLog: [],
                battleWin:res.data.win,
                drops: content.join(",")
            }, this.loopLog)
        })

    }

    loopLog = () => {
        if (this.state.renderLog.length >= this.state.logcat.length) {
            if (!this.state.battleWin) {
                this.setState({
                    battleStatus: BattleStatus.Prepare
                })
                ModalUtils.ShowSingleConfirm("很遗憾", "战斗失败", () => {
                });
                return;
            }
            this.setState({
                battleStatus: BattleStatus.Finished
            })
            if (this.state.drops.length > 0) {

            }
            return
        }

        this.setState({
            playerCurrentHp: this.state.logcat[this.state.renderLog.length].selfHP,
            masterCurrentHp: this.state.logcat[this.state.renderLog.length].targetHP,
            renderLog: this.state.logcat.filter((v, i) => i <= this.state.renderLog.length),
        });
        setTimeout(this.loopLog, 500)
    }

    render() {
        var type = this.props.getQueryParams("type");
        var maps = type === "1" ? this.props.config.maps : this.props.config.dungeons;
        var renders = [];
        this.state.renderLog.forEach((l) => renders.push(l))
        return <div className="battle-map-container">
            <NavBar onBack={() => this.props.navigate(-1)}
                    right={
                        <div>
                            <Button size={"small"} style={{}}
                                    disabled={this.state.battleStatus === BattleStatus.DuringBattle}
                                    onClick={this.doBattle}>开始战斗</Button>
                        </div>
                    }
                    style={{
                        height: "0.94rem",
                        background: "#09384D",
                        width: "100%",
                        boxSizing: "border-box"
                    }}>辑盗</NavBar>
            <div style={{display: "flex", justifyContent: "flex-start", width: "100%", margin: "0.1rem"}}>
                <Avatar style={{width: "1.2rem", height: "1.2rem", marginLeft: "0.1rem"}}
                        src={GetPlayerIcon(this.props.player.currentPlayer.wuxing as WuXing)}></Avatar>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "4rem",
                    alignItems: "start",
                    padding: "0 0.1rem",
                    background: "#A91B1B",
                    justifyContent: "space-around",
                    boxSizing: "border-box",
                    marginLeft: "0.1rem",
                    border: "1px solid #F03F03"
                }}>
                    <div style={{
                        fontSize: "0.24rem",
                        marginBottom: "0.1rem"
                    }}>{this.props.player.currentPlayer.nickname}</div>
                    <ProgressBar style={{width: "100%", '--fill-color': "#ff0000", "--track-color": "#616161"}}
                                 percent={this.state.playerCurrentHp / this.state.playerTotalHp * 100}></ProgressBar>
                    <div style={{
                        fontSize: "0.24rem",
                        marginBottom: "0.1rem"
                    }}>{this.state.playerCurrentHp}/{this.state.playerTotalHp}</div>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "end", width: "100%", margin: "0.1rem"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    padding: "0 0.1rem",
                    width: "4rem",
                    justifyContent: "space-around",
                    background: "#2B5B75",
                    marginRight: "0.1rem",
                    boxSizing: "border-box",
                    border: "1px solid #072335"
                }}>
                    <div>{this.state.masterName}</div>
                    <ProgressBar style={{
                        width: "100%",
                        '--fill-color': "#ff0000",
                        "--track-color": "#616161",
                        transform: "scaleX(-1)"
                    }} percent={this.state.masterCurrentHp / this.state.masterTotalHp * 100}></ProgressBar>
                    <div style={{
                        fontSize: "0.24rem",
                        marginBottom: "0.1rem"
                    }}>{this.state.masterCurrentHp}/{this.state.masterTotalHp}</div>
                </div>
                <Avatar style={{width: "1.2rem", height: "1.2rem", marginRight: "0.1rem"}}
                        src={`/images/icons/master/${this.state.masterIcon}.png`}></Avatar>
            </div>
            <div>———————————— 战斗日志 ————————————</div>
            <div className="battle-logcat-container" style={{
                flex: 1,
                overflow: "scroll",
                width: "100%",
                padding: "0 0.2rem",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column"
            }}>
                {
                    this.state.battleStatus === BattleStatus.Finished && this.state.battleWin && <div
                        className="battle-logcat">获取奖励:{this.state.drops}</div>
                }
                {
                    renders.reverse().map((item, index) => <div
                        dangerouslySetInnerHTML={{__html: `${this.state.renderLog.length - index}:` + item.log}}
                        className="battle-logcat" key={index}></div>)
                }

            </div>
        </div>;
    }
}

export default withRouter(MapBossBattle);
