import {Component, Fragment} from "react";
import {TaskItem} from "../../../component/TaskItem";
import "./index.less"
import {Button, Modal} from "antd-mobile";
import {IStore} from "../../../stores";
import withRouter from "../../../utlis/withRouter";
import {inject, observer} from "mobx-react";
import {APIHelper} from "../../../utlis/APIHelper";
import {ModalUtils} from "../../../utlis/modalUtils";

@inject("player", "config")
@observer
class TaskPage extends Component<IStore, any> {
    constructor(props) {
        super(props);
    }

    claimReward = () => {
        var value = 0;
        const {player, config} = this.props;
        player.dailyTasks.forEach((d) => value += Math.min(d.activePoint * (d.task.totalActivePoint / d.task.requireAmount), d.task.totalActivePoint))
        var rewardId = "";
        for (let i = 0; i < config.rewards.length; i++) {
            var reward = config.rewards[i];
            if (value < reward.activePointRequire) {
                ModalUtils.ShowSingleConfirm("错误", "活跃度不够，无法领取")
                return;
            }
            if (!player.claims.find((c) => c.reward.id === reward.id)) {
                rewardId = reward.id;
                break;
            }
        }
        APIHelper.ClaimReward(rewardId).then((res) => {
            var content = res.data.map((item) => {
                return {name: item.id === "10000" ? "经验值" : item.id === "10001" ? "银币" : this.props.config.itemTemplateMap.get(item.id).name, ...item}
            });
            Modal.show({
                title: "领取成功",
                closeOnMaskClick: true,
                content: <Fragment>
                    {
                        content.map((c) => {
                            return <div key={c.id}>{`获得${c.name}:x${c.amount}`}</div>
                        })
                    }
                </Fragment>
            })
        }).catch((res) => {
            ModalUtils.ShowSingleConfirm("错误", res)
        })
    }

    render() {
        var value = 0;
        this.props.player.dailyTasks.forEach((d) => value += Math.min(d.activePoint * (d.task.totalActivePoint / d.task.requireAmount), d.task.totalActivePoint))
        return <div className="task-details-container">
            <div className="task-header">
                <div style={{
                    width: "1.62rem",
                    height: "0.68rem",
                    background: "#3B7082",
                    border: "1px solid #072335",
                    lineHeight: "0.68rem",
                    fontSize: "0.28rem"
                }}>任务列表
                </div>
                <div style={{
                    fontSize: "0.28rem",
                    color: "#FFFF00",
                    width: "4rem",
                    textAlign: "left",
                    lineHeight: "0.68rem"
                }}>当前活跃度:{value}
                </div>
                <Button style={{
                    opacity: 0,
                    width: "1.06rem",
                    height: "0.68rem",
                    background: "#8A6DE9",
                    border: "1px solid #072335",
                    fontSize: "0.28rem"
                }}>返回</Button>
            </div>
            <div className="task-list">
                {
                    this.props.player.dailyTasks.map((item) => {
                        return <TaskItem key={item.id} data={item}></TaskItem>
                    })
                }
            </div>
            <div style={{
                width: "100%",
                height: "1.2rem",
                background: "#09384D",
                display: "flex",
                padding: "0.1rem",
                boxSizing: "border-box"
            }}>
                <img onClick={this.claimReward} src="/images/ui/task_award.png"
                     style={{width: "1rem", height: "1rem"}}/>
                <div className="task-progress">
                    <div className="task-progress-value" style={{width: (value) + "%"}}></div>
                    <div className="task-award-1">
                        <span className="award-label" style={{color: "white"}}>20活力</span>
                    </div>
                    <div className="task-award-2">
                        <span className="award-label" style={{color: "#C800FF"}}>30活力</span>
                    </div>
                    <div className="task-award-3">
                        <span className="award-label" style={{color: "#FFFF00"}}>50活力</span>
                    </div>
                    <div className="task-award-4">
                        <span className="award-label" style={{left: "-0.56rem", color: "#F6C509"}}>100活力</span>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(TaskPage)
