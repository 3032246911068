import {Component, Fragment} from "react";
import {Button, Ellipsis, Image} from "antd-mobile";
import {GetAssetIcon, GetAssetIconByIcon} from "../../utlis/AssetsUtils";
import {PlayerItemEntity} from "../../models/PlayerItemEntity";
import {GetItemType, ItemTopType} from "../../enums/ItemType";
import {ProductEntity} from "../../models/ProductEntity";

interface ProductItemProps {
    data: ProductEntity;
    onWxBuy: (ProductEntity) => void;
    onZfbBuy: (ProductEntity) => void;
}

export default class ProductItem extends Component<ProductItemProps, any> {

    render() {
        let {data} = this.props;
        return <div style={{
            width: "7.26rem",
            height: "1.12rem",
            margin: "1px 0 0",
            display: "flex",
            background: "#3B7082",
            padding: "0.06rem",
            boxSizing: "border-box",
            justifyContent:"space-between"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                // width: "4.5rem",
                flex: 1,
                textAlign: "left",
                margin: "0.1rem 0.12rem",
                justifyContent:"space-between"
            }}>
                <div style={{fontSize: "0.28rem", color: "#C800FF"}}>{data.name}</div>
                <Ellipsis style={{fontSize: "0.24rem"}} direction='end' rows={2} content={data.description}/>
            </div>
            <div style={{fontSize: "0.28rem", color: "#C800FF",lineHeight:"1rem"}}>价格:￥{data.price}</div>
            <div style={{width:"2rem",display:"flex"}}>
                <Button onClick={() => this.props.onWxBuy(data)} style={{
                    width: "0.8rem",
                    height: "0.64rem",
                    padding: "0",
                    fontSize: "0.24rem",
                    lineHeight: "0.64rem",
                    margin: "auto 0.12rem"
                }}>微信</Button>
                <Button onClick={() => this.props.onZfbBuy(data)} style={{
                    width: "0.8rem",
                    height: "0.64rem",
                    padding: "0",
                    fontSize: "0.24rem",
                    lineHeight: "0.64rem",
                    margin: "auto 0.12rem"
                }}>支付宝</Button>
            </div>
        </div>;
    }
}
