import {Component, Fragment} from "react";
import {inject, observer} from "mobx-react";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {IStore} from "../../../stores";
import {Button, Collapse, Ellipsis, Image, Modal, NavBar, Tabs, Toast} from "antd-mobile";
import {APIHelper} from "../../../utlis/APIHelper";
import {ModalUtils} from "../../../utlis/modalUtils";

interface MenpaiInfoProps extends IRouterProps, IStore {

}

interface MenpaiInfoState {
    menpais: Array<any>
    menpai: any
    currentLevel: number
}

@inject("player", "config")
@observer
class MenpaiInfo extends Component<MenpaiInfoProps, MenpaiInfoState> {
    private menpaiTimer: NodeJS.Timer;

    constructor(props) {
        super(props);
        this.state = {
            menpais: [],
            menpai: {},
            currentLevel: 1
        }
    }

    componentDidMount() {
        APIHelper.GetMenpaiList().then((res) => {
            this.setState({
                menpais: res.data,
                currentLevel: 1
            }, this.refreshMenpaiStat)
        });
    }

    componentWillUnmount() {
        if (this.menpaiTimer) {
            clearTimeout(this.menpaiTimer);
        }
    }

    refreshMenpaiStat = () => {
        if (this.menpaiTimer) {
            clearTimeout(this.menpaiTimer);
        }
        return APIHelper.StatMenpai(this.state.menpais.find((i) => i.level === this.state.currentLevel && i.wuxing === this.props.player.currentPlayer.wuxing).id).then((res) => {
            this.setState({
                menpai: res.data
            })
        }).finally(() => {
            this.menpaiTimer = setTimeout(this.refreshMenpaiStat, 5000)
        })
    }

    signMenpai = () => {
        const signMenpai = this.state.menpais.find((i) => i.level === this.state.currentLevel && i.status === 1 && i.wuxing === this.props.player.currentPlayer.wuxing)
        if (this.state.menpai.total >= 30) {
            ModalUtils.ShowFailedMsg("报名人数已满");
            return
        }
        if (!signMenpai) {
            return;
        }
        if (this.menpaiTimer) {
            clearTimeout(this.menpaiTimer);
        }
        ModalUtils.ShowLoading();
        return APIHelper.SignMenpai(signMenpai.id).then((res) => {
            ModalUtils.ShowSuccessMsg("报名成功");

            this.refreshMenpaiStat();
        }).catch((reason) => {
            ModalUtils.ShowFailedMsg(reason);

        }).finally(() => {
            this.menpaiTimer = setTimeout(this.refreshMenpaiStat, 5000)
            ModalUtils.HideLoading();

        })
    }

    goMenpaiBattle = () => {
        this.props.navigate({
            pathname: "/main/battle/menpai"
        }, {
            state: this.state.menpai
        })
    }
    onClaimClick = () => {
        var claimMenpai = this.state.menpais.find((i) => i.level === this.state.currentLevel && i.status === 3 && i.wuxing === this.props.player.currentPlayer.wuxing)
        if (!claimMenpai) {
            return;
        }
        ModalUtils.ShowLoading();
        APIHelper.ClaimMenpaiRank(claimMenpai.id).then((res: any) => {
            var content = res.data.map((item) => {
                return {name: item.id === "10000" ? "经验值" : item.id === "10001" ? "银币" : this.props.config.itemTemplateMap.get(item.id).name, ...item}
            });
            this.props.player.refreshPlayerInfo();
            Modal.show({
                title: "领取成功",
                closeOnMaskClick: true,
                content: <Fragment>
                    {
                        content.map((c) => {
                            return <div key={c.id}>{`获得${c.name}:x${c.amount}`}</div>
                        })
                    }
                </Fragment>
            })
        }).catch((reason) => {
            ModalUtils.ShowFailedMsg(reason);
        }).finally(() => {
            ModalUtils.HideLoading();
        })
    }

    onMenpaiRankClick = () => {
        var claimMenpai = this.state.menpais.find((i) => i.level === this.state.currentLevel && i.status === 3 && i.wuxing === this.props.player.currentPlayer.wuxing)
        if (!claimMenpai) return;
        ModalUtils.ShowLoading();
        APIHelper.ListMenpaiRank(claimMenpai.id).then((res) => {
            var index = res.data.findIndex((item) => {
                return item.player.id === this.props.player.playerId;
            })
            Modal.show({
                bodyClassName: "rank-modal",
                closeOnMaskClick: true,
                header: null,
                content: <div style={{position: "absolute", width: "100%", height: "100%"}}>
                    <div className="top-content">
                        <div>我的排名:{index + 1}</div>
                        {
                            res.data.map((item, index) => {
                                return <div className="rank-item">
                                    <div className="rank">{index + 1}</div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        lineHeight: "0.9rem",
                                        width: "4.6rem",
                                        background: "#A73232",
                                        padding: "0 0.2rem",
                                        fontSize: "0.3rem"
                                    }}>
                                        <div style={{color: "#FFCC00"}}>{item.player.nickname}</div>
                                        <div style={{fontSize: "0.28rem", fontWeight: "bold"}}>
                                            积分:{item.points}
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                    <div style={{
                        height: "0.8rem",
                        position: "absolute",
                        bottom: "3px",
                        width: "5.28rem",
                        display: "flex",
                        boxSizing: "border-box",
                    }}>
                        <a onClick={() => res.data[index].claim === 0 && this.onClaimClick()} style={{
                            flex: 1,
                            borderTop: "solid 1px #787332",
                            color: "white",
                            fontSize: "0.36rem",
                            fontWeight: "600",
                            textAlign: "center",
                            lineHeight: "0.8rem"
                        }}>{res.data[index].claim === 0 ? "领取奖励" : "已经领取"}</a>
                        <a onClick={Modal.clear} style={{
                            flex: 1,
                            borderTop: "solid 1px #787332",
                            color: "white",
                            fontSize: "0.36rem",
                            fontWeight: "600",
                            textAlign: "center",
                            lineHeight: "0.8rem"
                        }}>关闭</a>
                    </div>
                </div>
            })
        }).catch((err) => {
            ModalUtils.ShowFailedMsg(err);

        }).finally(() => {
            ModalUtils.HideLoading();
        })

    }


    getMenpaiIcon() {
        switch (this.props.player.currentPlayer.wuxing) {
            case 1:
                return "/images/ui/tianwang.png";
            case 2:
                return "/images/ui/tangmen.png";
            case 3:
                return "/images/ui/duanshi.png";
            case 4:
                return "/images/ui/gaibang.png";
            case 5:
                return "/images/ui/kunlun.png";
        }
        return "";
    }

    renderMenpai(level) {
        const {menpai} = this.state;
        return <div style={{margin: "0.2rem"}}>
            <div className="top-container">
                <div style={{display: "flex", paddingTop: "0.23rem"}}>
                    <div style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <img style={{width: "5.0rem", height: "2.18rem", marginBottom: "0.1rem"}}
                             src={this.getMenpaiIcon()}/>
                        <Button className="song-btn" disabled={menpai.signed || this.state.menpai.status !== 1}
                                onClick={() => {
                                    this.signMenpai()
                                }}>{this.state.menpai.status !== 1 ? "报名结束" : menpai.signed ? "已报名" : "报名"}</Button>
                        <div>报名数量:{this.state.menpai.total || 0}/30</div>
                    </div>
                </div>
                {
                    menpai.status == 1 ? <div style={{marginTop: "0.2rem"}}>活动将会在{menpai.startTime}开启</div> :
                        menpai.status == 2 ? <Fragment>
                            <div style={{marginTop: "0.2rem"}}>活动进行中</div>
                            {
                                menpai.signed ?
                                    <Button onClick={this.goMenpaiBattle} className="battle-btn">加入战斗</Button> :
                                    <Button disabled className="battle-btn">未报名</Button>
                            }
                        </Fragment> : <Fragment>
                            <div style={{marginTop: "0.2rem"}}>活动已经关闭</div>
                            {menpai.signed &&
                                <Button onClick={this.onMenpaiRankClick} className="battle-btn">查看结果</Button>}
                        </Fragment>
                }
            </div>
            <div className="bottom-container">
                <div className="title">规则说明:</div>
                <div className="menpai-tip"
                     style={{fontSize: "0.3rem", fontWeight: "bold"}}>勇夺冠军
                </div>
                <div>1.与参与报名的玩家每人完成一次战斗</div>
                <div>2.每场战斗获胜获得1点积分，失败积分不变，战斗初始积分0</div>
                <div>3.活动结束后会按照积分进行排名获取奖励</div>
            </div>

        </div>
    }

    render() {
        return <div className="menpai-container">
            <div className="menpai-tabs">
                <Button onClick={() => this.setState({currentLevel: 1}, this.refreshMenpaiStat)}
                        className={`menpai-tab-item ${this.state.currentLevel === 1 ? "active" : ""}`}
                        key="equipment">初级</Button>
                <Button onClick={() => this.setState({currentLevel: 2}, this.refreshMenpaiStat)}
                        className={`menpai-tab-item ${this.state.currentLevel === 2 ? "active" : ""}`}
                        key="crafting">中级</Button>
                <Button onClick={() => this.setState({currentLevel: 3}, this.refreshMenpaiStat)}
                        className={`menpai-tab-item ${this.state.currentLevel === 3 ? "active" : ""}`}
                        key="material">高级</Button>
                <div style={{flex: 1}}></div>
                <Button className="menpai-tab-item"
                        onClick={() => this.props.navigate(-1)}>返回</Button>
            </div>
            {
                this.renderMenpai(this.state.currentLevel)
            }
        </div>;
    }
}

export default withRouter(MenpaiInfo);
