import {Component} from "react";
import {Button, Ellipsis, Image} from "antd-mobile";
import ItemEntity from "../../models/ItemEntity";
import {GetAssetIcon} from "../../utlis/AssetsUtils";

interface CraftingGroupProps {
    data:ItemEntity;
    onGoCraft:(ItemEntity)=>void;
}

export class CraftingGroup extends Component<CraftingGroupProps, any> {

    render() {
        const {data} = this.props;
        return <div style={{width:"7.26rem",height:"1.12rem",margin:"1px 0 0",display:"flex",background:"#3B7082",padding:"0.06rem",boxSizing:"border-box"}}>
            <div style={{width:"1rem",height:"1rem",padding:"0.05rem",boxSizing:"border-box",position:"relative",border:"solid 0.05rem #F6C509"}}>
                <Image src={GetAssetIcon(data.type,data.requireLevel,data.wuxing)} style={{width:"100%",height:"100%"}}/>
            </div>
            <div style={{display:"flex",flexDirection:"column",width:"4.5rem",textAlign:"left",margin:"0 0.12rem"}}>
                <div style={{fontSize:"0.24rem"}}>{`${data.requireLevel}级装备`}</div>
                <Ellipsis style={{fontSize:"0.2rem"}} direction='end' rows={2} content={`${data.requireLevel}级装备`} />
            </div>
            <Button onClick={()=>this.props.onGoCraft(data)} style={{width:"1.8rem",height:"0.64rem",padding:"0",fontSize:"0.28rem",lineHeight:"0.64rem",margin:"auto 0.12rem"}}>前往合成</Button>
        </div>;
    }
}
