import react, {Component} from "react";
import withRouter, {IRouterProps} from "../../../utlis/withRouter";
import {Button, Loading, Modal, NavBar, Toast} from "antd-mobile";
import CraftItem from "../../../component/CraftItem";
import {inject, observer} from "mobx-react";
import {IStore} from "../../../stores";
import {WuXing} from "../../../enums/WuXing";
import ItemEntity from "../../../models/ItemEntity";
import {GetAssetIcon, GetAssetIconByIcon} from "../../../utlis/AssetsUtils";
import {APIHelper} from "../../../utlis/APIHelper";
import {ItemType} from "../../../enums/ItemType";
import {ModalUtils} from "../../../utlis/modalUtils";

interface CraftListState {
    itemTemplates: Array<ItemEntity>;
    currentSelected: string;
}

interface CraftListProps extends IStore, IRouterProps {
}

@inject("player", "config")
@observer
class CraftItemList extends Component<CraftListProps, CraftListState> {
    constructor(props) {
        super(props);
        this.state = {
            itemTemplates: [],
            currentSelected: ""

        }
    }

    componentDidMount() {
        var typeStr = this.props.getQueryParams("type")
        var type = parseInt(typeStr || ItemType.XuanJing.valueOf().toString());

        this.setState({
            itemTemplates: this.props.config.itemTemplates.filter((item) => {
                return item.type === type && item.materials.length>0
            }).sort((a, b) => parseInt(a.id)  - parseInt(b.id) )
        })
    }

    onSelected(item: ItemEntity, selected: boolean) {
        if (selected) {
            item && this.setState({currentSelected: item.id});
        } else {
            if (item.id === this.state.currentSelected) {
                this.setState({currentSelected: ""});
            }
        }
    }

    onCraftClick() {
        if (!this.state.currentSelected) {
            ModalUtils.ShowFailedMsg("请选择要合成的物品");
            return
        }
        ModalUtils.ShowLoading();
        var selectItem = this.state.itemTemplates.find((i) => i.id === this.state.currentSelected)
        APIHelper.CraftNewItem({itemId: selectItem.id, amount: 1}).then(() => {
            return this.props.player.refreshPlayerItems()
        }).then(() => {
            ModalUtils.ShowSuccessMsg("合成成功");

        }).catch((err) => {
            // Toast.clear();
            ModalUtils.ShowFailedMsg(err);

        }).finally(()=>{
            ModalUtils.HideLoading();
        });
    }

    render() {
        var selectItem = this.state.itemTemplates.find((i) => i.id === this.state.currentSelected)
        return <div className="craft-container">
            <NavBar onBack={()=>this.props.navigate(-1)} style={{height: "0.94rem", background: "#09384D",boxSizing:"border-box",width:"100%"}}>材料合成</NavBar>
            <div className="result-box">
                {
                    !selectItem && <div className="material-box">
                        请选择要合成的材料
                    </div>
                }
                {
                    selectItem && <div className="material-box">
                        <div style={{display: "flex", justifyContent: "space-around", marginTop: "0.62rem"}}>
                            {
                                selectItem.materials.map((item, index) => {
                                    var material = this.props.config.itemTemplateMap.get(item.item)
                                    return <div key={index} className="material-item">
                                        <img style={{width: "100%", height: "100%"}}
                                             src={GetAssetIconByIcon(material.type, material.icon, material.wuxing)}/>
                                        <div style={{
                                            position: "absolute",
                                            top: "1.02rem",
                                            fontSize: "0.2rem",
                                            textAlign: "center",
                                            width: "1.5rem",
                                            left: "-0.25rem"
                                        }}>
                                            {material.name}*{item.amount}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div style={{display: "flex", justifyContent: "center", marginTop: "1rem"}}>
                            <div className="material-item">
                                {
                                    selectItem &&
                                    <img style={{width: "100%", height: "100%"}}
                                         src={GetAssetIconByIcon(selectItem.type, selectItem.icon, selectItem.wuxing)}/>
                                }
                                <div style={{
                                    position: "absolute",
                                    height: "0.5rem",
                                    lineHeight: "0.5rem",
                                    top: "1.02rem",
                                    left: "-1rem",
                                    background: "#2C4F62",
                                    fontSize: "0.24rem",
                                    textAlign: "center",
                                    width: "3rem"
                                }}>
                                    {selectItem && selectItem.name}
                                </div>
                            </div>
                        </div>

                    </div>
                }

                <div style={{marginTop: "0.46rem", width: "5.4rem"}}>
                    <Button onClick={this.onCraftClick.bind(this)}
                            style={{width: "2rem", height: "0.68rem"}}>合成</Button>
                    <Button style={{width: "2rem", height: "0.68rem", marginLeft: "1.4rem"}}
                            onClick={() => this.props.navigate(-1)}>返回</Button>
                </div>
            </div>
            <div style={{
                flex: 1,
                width: "100%",
                overflow: "scroll",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#09384D"
            }}>
                {
                    this.state.itemTemplates.map((item) => {
                        return <CraftItem onSelect={(i, v) => this.onSelected(i, v)}
                                          selected={this.state.currentSelected === item.id} key={item.id}
                                          data={item}></CraftItem>
                    })
                }
            </div>
        </div>;
    }
}

export default withRouter(CraftItemList);
