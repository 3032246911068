import {Component} from "react";
import {Button, Ellipsis, Image} from "antd-mobile";
import SkillEntity from "../../models/SkillEntity";
import PlayerSkillEntity from "../../models/PlayerSkillEntity";
import {toJS} from "mobx";

interface SkillItemProps {
    data: PlayerSkillEntity;
    onClick: (data:PlayerSkillEntity) => void;
}

export default class SkillItem extends Component<SkillItemProps, any> {

    render() {
        let {data} = this.props;
        var color = data.skill.requireLevel <= 30 ? "#00EEFF" : (data.skill.requireLevel <= 70 ? "#C800FF" : "#F6C509")
        return <div style={{
            width: "7.26rem",
            height: "1.12rem",
            margin: "0.1rem 0 0",
            display: "flex",
            background: "#3B7082",
            padding: "0.06rem",
            justifyContent: "space-between",
            boxSizing: "border-box"
        }}>
            <Image src={`/images/icons/skill/${data.skill.icon}`} style={{width: "1rem", height: "1rem"}}/>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "4.5rem",
                textAlign: "left",
                margin: "0 0.12rem",
                boxSizing: "border-box",
                padding: "0 0 0.05rem 0",
                justifyContent: "space-between"
            }}>
                <div
                    style={{
                        fontSize: "0.28rem",
                        color: color
                    }}>{data.skill.name}{data.level === 0 ? `(未解锁)` : (" Lv." + data.level)}</div>
                <div
                    style={{fontSize: "0.24rem"}}>学习等级:Lv.{data.skill.requireLevel}</div>
            </div>
            <Button onClick={() => this.props.onClick(data)} style={{
                width: "0.8rem",
                height: "0.64rem",
                padding: "0",
                fontSize: "0.28rem",
                lineHeight: "0.64rem",
                margin: "auto"
            }}>查看</Button>
        </div>;
    }
}
