import {action, makeAutoObservable, toJS} from "mobx";
import {APIHelper, BASE_SERVER_URL} from "../utlis/APIHelper";
import {MessageData} from "../models/MessageData";

export class MessageStore {
    messages: Array<MessageData> = [];

    constructor() {
        makeAutoObservable(this);
        this.startSubscriptMessage();
    }

    @action
    async startSubscriptMessage() {
        try {
            for await (const val of this.subscript()) {
                this.appendNewMessage(val);
            }
        } catch (e) {
            setTimeout(() => {
                this.startSubscriptMessage()
            }, 5000);
        }

    }

    @action appendNewMessage(msg: string) {
        const messages = [...this.messages];
        if (messages.length < 100) {
            messages.push({...JSON.parse(msg),time:new Date()});
        } else {
            messages.splice(0, 1);
            messages.push({...JSON.parse(msg),time:new Date()});
        }
        this.messages = messages;
    }

    async* subscript(): AsyncGenerator<any> {
        const ev = new EventSource(`${BASE_SERVER_URL}/events`)
        const messageQueue = [];
        let abort = false;
        let reject: Function = () => {
        };
        ev.addEventListener("game_message", (msg) => {
            messageQueue.push(msg.data);
        })

        ev.addEventListener("end", (msg) => {
            ev.close();
            abort = true;
            reject();
        })

        ev.addEventListener("close", (msg) => {
            ev.close();
            abort = true;
            reject();
        })
        ev.onerror = function () {
            abort = true;
            reject();
        }

        while (!abort && (ev.readyState !== 2 || messageQueue.length > 0)) {
            if (messageQueue.length > 0) {
                yield messageQueue[0];
                messageQueue.splice(0, 1);
            } else {
                await new Promise((resolve, rej) => {
                    setTimeout(resolve, 100);
                    reject = rej
                });
            }
        }
    }


}
