import {Component} from "react";
import {Button, Ellipsis, Image} from "antd-mobile";
import SkillEntity from "../../models/SkillEntity";
import ItemEntity from "../../models/ItemEntity";
import {GetAssetIcon, GetAssetIconByIcon} from "../../utlis/AssetsUtils";

interface CraftItemProps {
    data: ItemEntity;
    onSelect?: (ItemEntity, bool) => void;
    selected?: boolean;
}

interface CraftItemState {
    selected: boolean;
}

export default class CraftItem extends Component<CraftItemProps, CraftItemState> {

    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected
        }
    }

    shouldComponentUpdate(nextProps: Readonly<CraftItemProps>, nextState: Readonly<CraftItemState>, nextContext: any): boolean {
        if (nextProps.selected !== nextState.selected) {
            this.setState({
                selected: nextProps.selected
            })
        }
        return true;
    }

    render() {
        let {data} = this.props;
        return <div style={{
            width: "7.26rem",
            height: "1.12rem",
            margin: "1px 0 0",
            display: "flex",
            background: "#3B7082",
            padding: "0.06rem",
            boxSizing: "border-box"
        }}>
            <div style={{
                width: "1rem",
                height: "1rem",
                padding: "0.05rem",
                boxSizing: "border-box",
                position: "relative",
                border: "solid 0.05rem blue"
            }}>
                <Image src={GetAssetIconByIcon(data.type, data.icon, data.wuxing)}
                       style={{width: "100%", height: "100%"}}/>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "4.5rem",
                textAlign: "left",
                margin: "0 0.12rem"
            }}>
                <div style={{fontSize: "0.24rem"}}>{data.name}</div>
                <Ellipsis style={{fontSize: "0.2rem"}} direction='end' rows={2} content={data.description}/>
            </div>
            <Button onClick={() => {
                this.setState({
                    selected: !this.state.selected
                }, () => {
                    this.props.onSelect && this.props.onSelect(data, this.state.selected);
                })
            }} style={{
                width: "1.06rem",
                height: "0.64rem",
                padding: "0",
                background: this.state.selected ? "#DD524D" : "none",
                fontSize: "0.28rem",
                lineHeight: "0.64rem",
                margin: "auto 0.12rem"
            }}>{this.state.selected ? "已选择" : "选择"}</Button>
        </div>;
    }
}
