import {Component} from "react";
import {Avatar} from "antd-mobile";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import getStoreFromProps from "../../utlis/getStoreFromProps";
import "./index.less"
import {GetPlayerIcon} from "../../utlis/AssetsUtils";

@inject("player")
@observer
export default class CustomHeaderBar extends Component<any, any> {
    private player = getStoreFromProps(this.props, "player");

    componentDidMount() {
        console.log(this.props)
    }

    render() {
        const {currentPlayer, equipmentProperty, playerArchives} = this.props.player;
        let archive = "";
        const equipArchive = playerArchives.find((p) => p.archive.type === 1 && p.equip === 1);
        if (equipArchive){
            archive += `[${equipArchive.archive.name}]`
        }
        const territoryArchive = playerArchives.find((p) => p.archive.type === 2 && p.equip === 1);
        if (territoryArchive){
            archive += `[${territoryArchive.archive.name}]`
        }
        const menpaiArchive = playerArchives.find((p) => p.archive.type === 3 && p.equip === 1);
        if (menpaiArchive){
            archive += `[${menpaiArchive.archive.name}]`
        }
        return <div className="custom-header">
            <Avatar style={{width: "0.98rem", height: "0.98rem"}}
                    src={GetPlayerIcon(this.player.currentPlayer.wuxing)}/>
            <div className="right-info">
                <div className="line1-con">
                    <div>
                        {currentPlayer.nickname}
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{lineHeight: "0.4rem", marginRight: "0.1rem"}}>
                            {currentPlayer.coins || 0}
                        </div>
                        <img src="/images/ui/coins_icon.png"/>
                    </div>
                </div>
                <div style={{textAlign:"left",fontSize:"0.24rem"}}>
                    {archive}
                </div>
                <div className="line2-con">
                    <div>
                        Lv.{currentPlayer.level}
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{lineHeight: "0.4rem", marginRight: "0.1rem"}}>
                            {currentPlayer.vitality || 0}
                        </div>
                        <img src="/images/ui/vitality_icon.png"/>
                    </div>
                </div>
            </div>
        </div>
    }
}
